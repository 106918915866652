import React, { useState,useEffect } from "react";
import style from "./Login.module.css";
import Group from "../../assests/Login_Group 104.png";
import { useNavigate } from "react-router-dom";
import { encryptJsonData } from "../EncryptionUtils"; // Adjust path if necessary
import { baseurl } from "../Url";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";

function Login() {
  const UserID = localStorage.getItem("USERID");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [popup, setPopup] = useState(false);
  const [emailForReset, setEmailForReset] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  async function userLogin(e) {
    e.preventDefault();
    const body = {
      email: email,
      Password: password,
    };
    const newdata = JSON.stringify(body);
    const encryptstring = encryptJsonData(newdata);
    await fetch(`${baseurl}/account/login`, {
      method: "POST",
      body: JSON.stringify({
        keys: encryptstring,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          if(data.data.subscription_status===false){
            navigate("/subscribe",{
              state:{
                Username: data.data.username,
                userID:data.data.user_id
              }
            })
          }
          else{
          localStorage.setItem(
            "access-token",
            JSON.stringify(data.data.access_token)
          );
          localStorage.setItem("ClubID", data.data.club_id);
          localStorage.setItem("ORGID", data.data.org_id);
          localStorage.setItem("USERID", data.data.user_id);
          localStorage.setItem("mentor", data.data.is_mentor);
          localStorage.setItem("username", data.data.username);
          navigate("/");
        }
        } else if (data.status === "error" || data.status === "failed") {
          if (
            data.message === "Login Failed. Please provide valid credentials."
          ) {
            setMessage("Invalid Credentials");
          } else if (
            data.message === "You are not authorised to access this page"
          ) {
            setMessage("You are not authorised to access this page");
          } else if (
            data.message === "please verify your email"
          ) {
            setMessage("Please verify your email");
            setTimeout(() => {
              navigate("/email/verification",{
                state:{
                  Email: email,
                }
              })
            }, 1000);
          } else {
            setMessage(data.message);
          }
        }
      });
  }

  async function forgetPassWord() {
    await fetch(`${baseurl}/forgot/pwd`, {
      method: "POST",
      body: JSON.stringify({
        email: emailForReset,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true || data.status === "success") {
          setPopup(false);
          setEmailSent(true);
        } else {
          setEmailError("Didn't find any account with that email");
        }
      });
  }
 

  return (
    <div className={style.Container}>
      <div className={style.loginBody}>
        <div className={style.loginBodyLeft}>
          <div className={style.formBox}>
            <div className={style.welcomeTxt}>
              <p className={style.welcomeHead}>
                Welcome to <span>English Wagon</span>
              </p>
              <p className={style.welcomeSubHead}>
                Start your journey to English mastery!
              </p>
            </div>
  
            <div className={style.formContainer}>
              <form onSubmit={userLogin} autoComplete="off">
                <div className={style.formFeilds}>
                  <div className={style.EmailBox}>
                    <input
                      type="text"
                      placeholder="Email"
                      name="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
  
                  <div className={style.passwordBox}>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="psw"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {showPassword ? (
                      <RxEyeOpen
                        className={style.eyeIco}
                        onClick={handleShowPassword}
                      />
                    ) : (
                      <RxEyeClosed
                        className={style.eyeIco}
                        onClick={handleShowPassword}
                      />
                    )}
                  </div>
                  {message && <div className={style.errorMessage}>{message}</div>}
                </div>
  
                <div className={style.password}>
  <label className={style.customCheckbox}>
    <input type="checkbox" name="remember" />
    <span className={style.checkmark}></span>
    &nbsp;Remember me
  </label>
  <p onClick={() => navigate("/reset/password")}>Forgot password?</p>
</div>

                <button type="submit">Login</button>
  
                <div className={style.signUp}>
                  <p>New to English Wagon?</p>
                  <p>
                    <span onClick={() => navigate("/register")}>Sign up</span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>

       <div className={style.loginBodyRight}>
          <div className={style.groupImg}>
            <img src={Group} alt="English Wagon group" />
          </div>
       </div>
      </div>

      {popup && (
        <>
          <div className={style.overlay} />
          <div className={style.popup}>
            <p>Enter your account email to receive a password reset link</p>
            <input
              value={emailForReset}
              onChange={(e) => setEmailForReset(e.target.value)}
              placeholder="Email"
              required
            />
            {emailError && <p className={style.errorMessage}>{emailError}</p>}
            <div className={style.popupButton}>
              <button
                className={style.popupSend}
                onClick={() => {
                  if (emailForReset) forgetPassWord();
                }}
              >
                Send
              </button>
              <button
                className={style.popupCancel}
                onClick={() => setPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}

      {emailSent && (
        <>
          <div className={style.overlay} />
          <div className={style.popup}>
            <p>Please check your email for a reset link.</p>
            <div className={style.popupButton}>
              <button onClick={() => setEmailSent(false)}>OK</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
