import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./TopicSingle.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { FaCheckCircle } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { PiVideoCameraLight } from "react-icons/pi";
import { BiMicrophone } from "react-icons/bi";
import { RiAttachment2 } from "react-icons/ri";
import { FiPower } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { PiNumberCircleZero } from "react-icons/pi";
import Loader from "../Loder/Loader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { IoSend } from "react-icons/io5";
import { GrCaretPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import Editor from "../Editor/Editor";
import { BsEmojiSmile, BsFillQuestionCircleFill } from "react-icons/bs";
import { BiFile } from "react-icons/bi";
import BotImage from "../../assests/image_2023_09_20T10_25_15_859Z.png";
import uploadImage from "../../assests/BrownUploadIcon.png";
import EditImage from "../../assests/WhiteEditpen.png";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FloatingIcon from "../../assests/TeacherIcon.png";
import { FaEdit } from "react-icons/fa";
import { FaRegComment } from "react-icons/fa";
import UploadLogo from "../../assests/UploadLogo.png";
import chatLogoImage from "../../assests/Hi_Iam_your_Teacher_buddy.png";
import { baseurl } from "../Url";
import { PiExam } from "react-icons/pi";
import { MdOutlineTopic } from "react-icons/md";
import Lottie from "lottie-react";
import { AnimationData } from "../../Animation/AnimationData";
import { Bar } from 'react-chartjs-2';
import { HiSpeakerWave } from "react-icons/hi2";
import Whiteboard from '../WhiteBoard/WhiteBoard';
import { BiClipboard } from "react-icons/bi";


const TopicSingle = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const mentor = localStorage.getItem("mentor");
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const ClubID = localStorage.getItem("ClubID");
  const main_topic_id = localStorage.getItem("main_topic_id");


  const clubIdsArray = ClubID.split(",");
  const [topicDetail, setTopicDetail] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [assesment, setAssesment] = useState([]);
  const [answerDetails, setAnswerDetails] = useState([]);
  const [answer, setAnswer] = useState("");
  const [questionStates, setQuestionStates] = useState([]);
  const [topicName, setTopicName] = useState("");
  const [popup, setPopup] = useState(false);
  const [summary, setSummary] = useState(false);
  const [explain, setExplain] = useState(false);
  const [upload, setUpload] = useState(false);
  const [copyLesson, setCopyLesson] = useState(false);
  const [attachmentLesson, setAttachmentLesson] = useState(false);
  const [editLesson, setEditLesson] = useState(false);

  const [summarisedData, setSummarisedData] = useState("");
  const [explainedData, setExplainedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [noofpages, setNoOfPages] = useState(0);
  const [bot, setBot] = useState(false);
  const [roomID, setRoomID] = useState("");
  const [messages, setMessages] = useState([]);
  const [chat, setChat] = useState("");
  const [array, setArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [chatLoader, setChatloader] = useState(false);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imageUrl, setImageUrl] = useState({});
  const [type, setType] = useState("");
  const learnertoken = localStorage.getItem("learnertoken");
  const [token, setToken] = useState(learnertoken ? learnertoken : key);
  const learneruserid = localStorage.getItem("learneruserid");
  const [userId, setUserID] = useState("");
  const [currentItem, setCurrentItem] = useState({});
  const [aiupload, setAiupload] = useState(false);
  const [aitext, setAiText] = useState("");
  const [video, setVideo] = useState(false);
  const [url, setUrl] = useState("");
  const [videourl, setVideoUrl] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [chatIcon, setChatIcon] = useState(true);
  const [activityPopup, setActivityPopup] = useState(false);
  const [activitySelect, setActivitySelect] = useState(false);
  const [copyActivity, setCopyActivity] = useState(false);
  const [attachmentActivity, setAttachmentActivity] = useState(false);
  const [videoActivity, setVideoActivity] = useState(false);
  const [activityUrl, setActivityUrl] = useState(false);
  const [activityTitle, setActivityTitle] = useState("");
  const [activityDetails, setActivityDetails] = useState([]);
  const [activityUploadUrl, setActivityUploadUrl] = useState("");
  const [activityImage, setActivityImage] = useState({ preview: "", data: "" });
  const [activityVideo, setActivityVideo] = useState({});
  const [activityTextEdit, setActivityTextEdit] = useState(false);
  const [activityTitleEdit, setActivityTitleEdit] = useState("");
  const [activityID, setActivityID] = useState("");
  const [contentId, setContentId] = useState("");
  const [deleteActivity, setDeleteActivity] = useState(false);
  const [activityUrlEdit, setActivityUrlEdit] = useState(false);
  const [activityEditUrl, setActivityEditUrl] = useState("");
  const [comment, setComment] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [lessonLoading, setLessonLodaing] = useState(true);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [learningTitle, setLearningTitle] = useState("");
  const [validatClicked, setValidateClicked] = useState(false);
  const [assesmentError, setAssesmentError] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);
  const [assesmentComentData, setAssessmentComentData] = useState([]);
  const [assessmentCommentText, setAssessmentCommentText] = useState("");
  const [pdfLesson, setPdfLesson] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [wordLesson, setWordLesson] = useState(false);
  const [pdfUrl, setPDFURL] = useState("");
  const [analyticsData, setAnalyticsData] = useState([]);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const[total,setTotal]=useState("")
  const[correct,setCorrect]=useState("")
  const[inCorrect,setIncorrect]=useState("")
  const[unAttended,setUnAttended]=useState("")
  const[totalMark,setTotalMark]=useState("")
  const[obtainedMark,setObtainedmark]=useState("")
  // const[whiteBoradShow,setWhiteBoardShow]=useState(false)
  const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)
  const [labData,setLabData]=useState([])


  const location = useLocation();
  const state = location.state;
  const Topic = state?.singleTopic;
  const learning_id = state?.learning;
  // const main_topic_id = state?.mainid;
  const admin_Mapped_Lessons = localStorage.getItem("adminmapped");
  const peerRole = state?.peerRole;

  const [AiId, setAiID] = useState("");

  const UserName = localStorage.getItem("username");

  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    if (lessonLoading) {
      return;
    } else {
      setSelectedTab(newValue);
    }
  };

  const [timer, setTimer] = useState(60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [hasClickedTextArea, setHasClickedTextArea] = useState(false);
  const [valid, setValid] = useState(false);
  const [answersProvided, setAnswersProvided] = useState(false);
  const [answerLoader, setAnswerLoader] = useState(false);
  const [answerComment, setAnswerComment] = useState([]);
  const [answers, setAnswers] = useState([]);
  const[graphData,setGraphData]=useState([])
  const [currentTime, setCurrentTime] = useState("");
  const[answerTime,setAnswerTime]=useState("")
  const [selectedNumber, setSelectedNumber] = useState(1);

  useEffect(() => {
    // Update user_ID when peerRole changes
    setUserID(peerRole !== undefined ? learneruserid : USERID);
  }, [peerRole]);


  //editor
  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    // editor.ui.view.editable.element.style.Height = "300px";
  };
  const [lessoneditData, setLessonEditData] = useState("");
  const handlelessonEditorChange = (event, editor) => {
    const data = editor.getData();
    setLessonEditData(data);
  };
  const [activityTextData, setActivityTextData] = useState("");
  const handleactivityEditorChange = (event, editor) => {
    const data = editor.getData();
    setActivityTextData(data);
  };
  const [activityeditTextData, setActivityeditTextData] = useState("");
  const handleactivityUpdateChange = (event, editor) => {
    const data = editor.getData();
    setActivityeditTextData(data);
  };
  // const[commentText,setCommentText]=useState("")
  const assessmentCommentEditorChange = (event, editor) => {
    const data = editor.getData();
    setAssessmentCommentText(data);
  };
  const [activityComments, setActivityComments] = useState({});
  const commentEditorChange = (event, editor, activityId) => {
    const data = editor.getData();
    setActivityComments((prevComments) => ({
      ...prevComments,
      [activityId]: data,
    }));
  };

  useEffect(() => {
    // console.log(lessoneditData);
  }, [lessoneditData]);

  useEffect(() => {
    console.log(editorData);
  }, [editorData]);

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // Scroll to the bottom when messages change
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    console.log(image);
  }, [image]);
  async function Topic_Single() {
    await fetch(`${baseurl}/ai/lesson/generation/for/user`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        learning_id: learning_id,
        main_topic_id: main_topic_id,
        user_id: userId,
        admin_mapped: admin_Mapped_Lessons,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        setTopicName(data.data[0]?.topic_name);
        // setUserID(data.data[0].User_ID);
        setNoOfPages(data.data[0]?.no_of_pages);
        setAiID(data.data[0]?.ai_id);

        setRoomID(data.data[0]?.room_id);
      });
  }

  async function assesmentDetails() {
    setLoading(true);
    await fetch(`${baseurl}/ai/assignment/generation/for/user/new`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        learning_id: learning_id,
        main_topic_id: main_topic_id,
        user_id: userId,
        admin_mapped: admin_Mapped_Lessons,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        setAssesment(data.data);
        // const assessmentsArray = JSON.parse(data.data[0].assessments).items;
        const assessments = data.data[0]?.assessments;
        if (assessments) {
          const publishedAssessments = assessments.filter(assessment => !assessment.publish_status || assessment.publish_status === "publish");
          // Set the state array directly to the filtered assessments
          setArray(publishedAssessments);
        }
      });
  }

  async function assesmentAnswer() {
    console.log(currentTime,"api");
    const questionId = currentItem.id ? currentItem.id.toString() : "";
    const calculatedTime = (parseInt(currentItem.time) * 60) - remainingTime; 
    const modifiedAnswers = answers.map(answerGroup => (
      Array.isArray(answerGroup) ? // Check if answerGroup is an array
        answerGroup.map(answer => ({
            clueID: answer.clueID,
            clueNumber: answer.clueNumber,
            cluevalue: answer.cluevalue,
        })) :
        answerGroup 
  ));
    await fetch(`${baseurl}/user/assignment/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        question: currentItem.question,
        question_id: questionId,
        answer:currentItem.type === 'cross word puzzle'?modifiedAnswers: answers,
        ai_id: AiId,
        main_topic_id: main_topic_id,
        user_id: USERID,
        question_type: currentItem.type,
        options: currentItem.options ? currentItem.options : [],
        unique_id: currentItem.unique_id,
        generated_by: currentItem.generation === "admin" ? "admin" : "",
        left: currentItem.left ? currentItem.left : [],
        right: currentItem.right ? currentItem.right : [],
        time_taken:calculatedTime.toString(),
        markif_correct:currentItem.mark,
        markif_incorrect:currentItem.negative_mark

      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnswerDetails(data.data);
        setAnswersProvided(true);
        answerGet();
        assesmentSummaryDetails()
        setValidateLoader(false);
        console.log(data.data.result);
        if (data.data[0].answer_is === "Correct") {
          setValidationSuccess(true);
        } else {
          setValidationFail(true);
        }
      });
  }

  async function assesmentMore() {
    await fetch(
      `${baseurl}/ai/assignment/generation/for/user/more/assignments`,
      {
        method: "POST",
        body: JSON.stringify({
          Organization_ID: OrgID,
          topic_id: Topic.id,
          topic_name: Topic.value,
          learning_id: learning_id,
          main_topic_id: main_topic_id,
          user_id: USERID,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setAssesment(data.data);
          const filteredAssessments = data.data[0].assessments.filter(assessment => !assessment.publish_status || assessment.publish_status === "publish");
          const newArray = [...array, filteredAssessments];
          setArray(newArray);
          assesmentDetails();
          setLoading(false);
        } else {
          setLoading(false);
          setAssesmentError(true);
          setTimeout(() => {
            setAssesmentError(false);
          }, 3000);
        }
      });
  }

  async function Summarise() {
    await fetch(`${baseurl}/user/lesson/summarise`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: USERID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        // const summary=data.data.split("\n");
        setSummarisedData(data.data);
      });
  }

  async function Explanation() {
    await fetch(`${baseurl}/user/lesson/explain`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: USERID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        const explaination = data.data.split("\n");
        // setExplainedData(explaination);
      });
  }

  async function Pagination(page) {
    await fetch(`${baseurl}/lesson/pagination`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        ai_id: AiId,
        user_id: userId,
        page_num: page,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setType(data.data[0]?.type);
        if (data.data[0]?.type === "image") {
          setImageUrl(data.data[0]);
        } else if (data.data[0]?.type === "video_url") {
          setVideoUrl(data.data[0]);
        } else if (data.data[0]?.type === "pdf") {
          setPDFURL(data.data[0]);
        } else if (data.data[0]?.type === "lesson") {
          // Check if text is a URL
          const urlPattern = /^https?:\/\/.*\.(?:png|jpg|jpeg|gif)$/i;
          if (urlPattern.test(data.data[0]?.text)) {
            // It's an image URL
            setImageUrl({ text: data.data[0]?.text });
            setType("image");
          } else {
            const Text = data.data[0]?.text.split("\n");
            setTopicDetail(data.data[0]);
            setLessonEditData(data.data[0]?.text.replace(/\n/g, "<br/>"));
          }
        } else {
          const Text = data.data[0]?.text.split("\n");
          setTopicDetail(data.data[0]);
          setLessonEditData(data.data[0]?.text.replace(/\n/g, "<br/>"));
        }
        setLessonLodaing(false);
        setLoading(false);
      });
  }

  async function chatRoom() {
    await fetch(`${baseurl}/user/room/chat/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        room_id: roomID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMessages(data.data);
        setChatloader(false);
      });
  }

  async function chatSend(chatText) {
    console.log("chat");
    await fetch(`${baseurl}/ai/tutor/chat`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        topic_id: Topic.id,
        room_id: roomID,
        content: chatText,
        ai_id: AiId,
        user_id: USERID,
        type: "text",
        topic_name: Topic.value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          chatRoom();
        }
      });
  }
  async function chatSendInput() {
    console.log("chat");
    await fetch(`${baseurl}/ai/tutor/chat`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        topic_id: Topic.id,
        room_id: roomID,
        content: chat,
        ai_id: AiId,
        user_id: USERID,
        type: "text",
        topic_name: Topic.value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          chatRoom();
          setChat("");
        }
      });
  }

  async function assessmentCommentAdd() {
    await fetch(`${baseurl}/comment/add/assessment`, {
      method: "POST",
      body: JSON.stringify({
        content: assessmentCommentText,
        ai_id: AiId,
        unique_id: currentItem.unique_id,
        main_topic_id: main_topic_id,
        created_by: {
          id: USERID,
          name: UserName,
          main_topic_id: main_topic_id,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          assessmentCommentGet();
          setAssessmentCommentText("");
        }
      });
  }
  async function assessmentCommentGet() {
    await fetch(`${baseurl}/comments/get/assessment/question`, {
      method: "POST",
      body: JSON.stringify({
        ai_id: AiId,
        unique_id: currentItem.unique_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          console.log(data.data[0]?.comments);
          setAssessmentComentData(data.data);
        }
      });
  }

  async function textUpload() {
    await fetch(`${baseurl}/user/lesson/upload/text`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: userId,
        content: editorData,
        title: learningTitle,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setEditorData("");
          setLearningTitle("");
        }
      });
  }
  async function imageUpload() {
    let formData = new FormData();
    formData.append("image", image.data);
    formData.append("Organization_ID", OrgID);
    formData.append("topic_id", Topic.id);
    formData.append("ai_id", AiId);
    formData.append("user_id", userId);
    formData.append("title", learningTitle);
    console.log(formData);
    await fetch(`${baseurl}/gallery`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setLearningTitle("");
        }
      });
  }
  async function videoUpload() {
    let formData = new FormData();
    formData.append("video", selectedFile);
    formData.append("Organization_ID", OrgID);
    formData.append("topic_id", Topic.id);
    formData.append("ai_id", AiId);
    formData.append("user_id", userId);
    formData.append("title", learningTitle);
    console.log(formData);
    await fetch(`${baseurl}/video/upload`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setLearningTitle("");
        }
      });
  }

  async function aiUpload() {
    console.log("aiUpload");
    await fetch(`${baseurl}/user/lesson/upload/content_generated_ai`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: userId,
        content: aitext,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
        }
      });
  }
  async function pdfUpload() {
    let formData = new FormData();
    formData.append("pdf", pdfFile);
    formData.append("Organization_ID", OrgID);
    formData.append("ai_id", AiId);
    formData.append("title", learningTitle);
    console.log(formData);
    await fetch(`${baseurl}/pdf/upload/member`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setLearningTitle("");
        }
      });
  }
  async function urlUpload() {
    console.log("urlupload");
    await fetch(`${baseurl}/user/upload/url`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: userId,
        content: url,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
        }
      });
  }

  async function lessonEdit() {
    console.log("lessonedit");
    await fetch(`${baseurl}/lesson/edit`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        page_num: current,
        ai_id: AiId,
        user_id: userId,
        lesson: lessoneditData,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLoading(false);
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
        }
      });
  }

  async function activityGet() {
    console.log("activity");
    await fetch(`${baseurl}/activity/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: userId,
        main_topic_id: main_topic_id,
        learning_id: learning_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setActivityDetails(data.data);
        console.log(activityDetails.length);
        setLoading(false);
      });
  }
  async function activityTextUpload(type) {
    await fetch(`${baseurl}/activity/text_url/upload`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: userId,
        main_topic_id: main_topic_id,
        learning_id: learning_id,
        title: activityTitle,
        text: activityTextData,
        type: type,
        login_user: {
          id: USERID,
          name: UserName,
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setActivityTitle("");
          activityGet();
        }
      });
  }
  async function activityUrlUpload(type) {
    await fetch(`${baseurl}/activity/text_url/upload`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        topic_id: Topic.id,
        ai_id: AiId,
        user_id: userId,
        main_topic_id: main_topic_id,
        learning_id: learning_id,
        title: activityTitle,
        text: activityUploadUrl,
        type: type,
        login_user: {
          id: USERID,
          name: UserName,
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setActivityTitle("");
          activityGet();
        }
      });
  }
  async function activityVideoUpload() {
    let formData = new FormData();
    formData.append("video", activityVideo);
    formData.append("org_id", OrgID);
    formData.append("topic_id", Topic.id);
    formData.append("ai_id", AiId);
    formData.append("user_id", userId);
    formData.append("title", activityTitle);
    formData.append("main_topic_id", main_topic_id);
    formData.append("learning_id", learning_id);
    formData.append("type", "video");
    const loginUserData = {
      id: USERID,
      name: UserName,
    };
    formData.append("login_user", JSON.stringify(loginUserData));
    console.log(formData);
    await fetch(`${baseurl}/activity/video/upload`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setActivityTitle("");
          activityGet();
        }
      });
  }
  async function activityImageUpload() {
    let formData = new FormData();
    formData.append("image", activityImage.data);
    formData.append("org_id", OrgID);
    formData.append("topic_id", Topic.id);
    formData.append("ai_id", AiId);
    formData.append("user_id", userId);
    formData.append("title", activityTitle);
    formData.append("main_topic_id", main_topic_id);
    formData.append("learning_id", learning_id);
    formData.append("type", "image");
    const loginUserData = {
      id: USERID,
      name: UserName,
    };
    formData.append("login_user", JSON.stringify(loginUserData));
    console.log(formData);
    await fetch(`${baseurl}/activity/image/upload`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUploadLoader(false);
          setActivityTitle("");
          activityGet();
        }
      });
  }

  async function activityEdit() {
    await fetch(`${baseurl}/activity/edit`, {
      method: "POST",
      body: JSON.stringify({
        activity_id: activityID,
        content_id: contentId,
        title: activityTitleEdit,
        text: activityeditTextData,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLoading(false);
          setActivityTitleEdit("");
          setActivityID("");
          activityGet();
          setContentId("");
        }
      });
  }
  async function activityUpdateUrl() {
    await fetch(`${baseurl}/activity/edit`, {
      method: "POST",
      body: JSON.stringify({
        activity_id: activityID,
        content_id: contentId,
        title: activityTitleEdit,
        text: activityEditUrl,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLoading(false);
          setActivityTitleEdit("");
          setActivityID("");
          activityGet();
          setContentId("");
        }
      });
  }

  async function activityDelete() {
    await fetch(`${baseurl}/activity/delete`, {
      method: "POST",
      body: JSON.stringify({
        activity_id: activityID,
        content_id: contentId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLoading(false);
          setActivityTitle("");
          setActivityID("");
          activityGet();
          setContentId("");
        }
      });
  }
  // async function commentGet(activityid,contentid) {
  //   await fetch(`${baseurl}/comments/get`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       activity_id:activityid,
  //       content_id:contentid,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);

  //     });
  // }
  async function commentAdd(activityid, contentid, comment) {
    await fetch(`${baseurl}/comment/add`, {
      method: "POST",
      body: JSON.stringify({
        activity_id: activityid,
        content_id: contentid,
        content: comment,
        created_by: {
          id: USERID,
          name: UserName,
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          activityGet();
          // setCommentText("")
          setActivityComments((prevComments) => ({
            ...prevComments,
            [contentid]: "",
          }));
        }
      });
  }
  async function answerGet() {
    await fetch(`${baseurl}/user/assignment/answers/each_question`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        org_id: OrgID,
        ai_id: AiId,
        question: currentItem.question,
        question_id: currentItem.id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnswerComment(data.data);
      });
  }

  async function analyticsGet() {
    await fetch(`${baseurl}/topic/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: userId,
        club_ids: clubIdsArray,
        main_topic_id: main_topic_id,
        topic_id: Topic.id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnalyticsData(data.data[0].Learner_Engagement);
      });
  }
  async function getLearningLevel() {
    await fetch(`${baseurl}/topic/dashboard/learning_level`, {
      method: "POST",
      body: JSON.stringify({
        ai_id: AiId,
        user_id: userId,
        main_topic_id: main_topic_id,
        topic_id: Topic.id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setGraphData(data.data);
      });
  }

  async function assesmentSummaryDetails() {
    await fetch(`${baseurl}/ai/assignment/summary`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        user_id: userId,
        ai_id:AiId
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.data.length>0){
        setTotal(data.Total_questions[0].Total_questions)
        setCorrect(data.Correct_ans[0].Correct_ans)
        setIncorrect(data.Incorrect_ans[0].Incorrect_ans)
        setUnAttended(data.Skipped_questions[0].Skipped_questions)
        setTotalMark(data.Total_mark[0].Total_mark)
        setObtainedmark(data.Obtained_mark[0].User_Secured_mark)
        }
        
      });
  }


  async function labsGet() {
    await fetch(`${baseurl}/topic/labs/list`, {
      method: "POST",
      body: JSON.stringify({
        topic_id: Topic.id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLabData(data.data)
      });
  }



  useEffect(() => {
    if (Object.keys(currentItem).length !== 0) {
      answerGet();
      assessmentCommentGet();
      assesmentSummaryDetails();
  }
  console.log(currentItem);
  }, [currentItem]);

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const handleVideoFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handlePdfFileChange = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const handleActivityFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setActivityImage(img);
  };
  const handleActivityVideoFileChange = (event) => {
    console.log(event);
    setActivityVideo(event.target.files[0]);
  };
  useEffect(() => {
    console.log(activityVideo);
  }, [activityVideo]);

  const decrement = () => {
    const updatedCurrent = current - 1;
    setCurrent(updatedCurrent);
    Pagination(updatedCurrent);
  };
  const increment = () => {
    const updatedCurrent = current + 1;
    setCurrent(updatedCurrent);
    Pagination(updatedCurrent);
  };
  const handleNext = () => {
    if (answerDetails.length === 0 && answers.length > 0) {
      handleNextValidation();
    } else {
      setAnswers([]);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
      setSelectedNumber((prevIndex) => (prevIndex + 1))
      clearTimeout(Timer);
      setTimerExpired(false);
      setTimerStarted(false);
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
      setAnswer("");
      setValid(false);
    }
    setValidateClicked(false);
  };

  const handleSkip = async () => {
    clearTimeout(Timer);
    if (timerStarted && answerDetails.length === 0) {
      await assesmentAnswer();
      setTimeout(() => {
        clearTimeout(Timer);
        setTimerExpired(false);
        setTimerStarted(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
        setSelectedNumber((prevIndex) => (prevIndex + 1))
        setIsTextareaDisabled(false);
        setAnswerDetails([]);
        setAnswer("");
        setAnswers([]);
      }, 2000);
    } else {
      clearTimeout(Timer);
      setTimerExpired(false);
      setTimerStarted(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
      setSelectedNumber((prevIndex) => (prevIndex + 1))
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
      setAnswer("");
      setAnswers([]);
      setValid(false);
    }
    setValidateClicked(false);
  };

  const handlePrevious = () => {
    // Decrement the currentIndex to show the previous item
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + array.length) % array.length
    );
  };

  useEffect(() => {
    if (array && array.length > 0) {
      // const currentItem = array[currentIndex];
      setCurrentItem(array[currentIndex]);
    }
  }, [array, currentIndex]);

  
  useEffect(() => {
    console.log(current);
    if (AiId !== "") {
      Pagination(1);
    }
  }, [AiId]);

  useEffect(() => {
  if(userId!==""){
    Topic_Single();
    // assesmentDetails();
    console.log(topicDetail);
    }
  }, [userId]);

  useEffect(() => {
    if (validationSuccess) {
      const timeoutId = setTimeout(() => {
        setValidationSuccess(false);
      }, 3000);
      console.log(validationSuccess);
      return () => clearTimeout(timeoutId);
    }
  }, [validationSuccess]);

  useEffect(() => {
    if (validationFail) {
      const timeoutId = setTimeout(() => {
        setValidationFail(false);
      }, 3000);
      console.log(validationFail);
      return () => clearTimeout(timeoutId);
    }
  }, [validationFail]);

  // useEffect(() => {
  //   if (assesment.length > 0) {
  //     const initialQuestionStates = assesment[0].assessments.map(() => ({
  //       answer: "", // Initialize answer state for each question
  //       answerDetails: [], // Initialize answerDetails state for each question
  //     }));
  //     setQuestionStates(initialQuestionStates);
  //   }
  // }, [assesment]);

  const [timerStarted, setTimerStarted] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [isTextareaDisabled, setIsTextareaDisabled] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  let Timer;
  useEffect(() => {
    if (timerStarted && !timerExpired && remainingTime > 0) {
      Timer = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000); // 1 second
    } else if (remainingTime === 0) {
      setTimerExpired(true);
      setIsTimerRunning(false);
      // handleTimerExpiration();
    }

    return () => {
      clearTimeout(Timer);
    };
  }, [timerStarted, timerExpired, remainingTime]);

  const handleTextareaClick = () => {
    if (!timerStarted) {
      // setTimerStarted(true);
      // setIsTimerRunning(true);
    }
  };
  const handleCheckCircleClick = () => {
    assesmentAnswer();
    setValidateLoader(true);
    setIsTextareaDisabled(true);
    setIsTimerRunning(false);
    if (!timerExpired) {
      clearTimeout(Timer); // Stop the timer
    }
    setValid(false)
  };


  const handleTimerExpiration = async () => {
    if (timerStarted && !timerExpired && remainingTime === 0) {
      clearTimeout(Timer); // Clear the timer

      // Call the assessment answer API
      await assesmentAnswer();
      setTimeout(() => {
        clearTimeout(Timer);
        setTimerExpired(false);
        setTimerStarted(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
        setIsTextareaDisabled(false);
        setAnswerDetails([]);
        setAnswer("");
      }, 2000);
    }
  };
  const handleNextValidation = async () => {
    clearTimeout(Timer);
    await assesmentAnswer();
    setTimeout(() => {
      clearTimeout(Timer);
      setAnswers([]);
      setTimerExpired(false);
      setTimerStarted(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
      setSelectedNumber((prevIndex) => (prevIndex + 1))
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
      setAnswer("");
    }, 2000);
  };
  const handleDeleteBinClick = () => {
    setAnswer("");
    setAnswerDetails([]);
    setTimerStarted(false);
    setIsTimerRunning(false);
  };
  useEffect(() => {
    console.log(currentTime);
  }, [currentTime]);

  const numbers = Array.from({ length: array.length }, (_, index) => index + 1);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (chat !== "") {
        chatSendInput();
        setChatloader(true);
      }
    }
  };
  useEffect(() => {
    console.log("video", videourl);
  }, [videourl]);
  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === "Escape") {
        setTimeout(() => {
          setPopup(false);
          setLoading(false);
          setBot(false);
          setChatIcon(true);
        }, 500);

        setClosing(true);
      }
    }
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const commentDivRef = useRef(null);
  useEffect(() => {
    if (comment && commentDivRef.current) {
      commentDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [comment]);
  useEffect(() => {
    console.log(commentId);
  }, [commentId]);

  const inputRef = useRef(null);
  useEffect(() => {
    if (bot && inputRef.current) {
      inputRef.current.focus();
    }
  }, [bot]);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isChatMouseOver, setIsChatMouseOver] = useState(false);
  const [isEditMouseOver, setIsEditmouseOver] = useState(false);

  const handleMouseOver = (isChat) => {
    if (isChat === "chat") {
      setIsChatMouseOver(true);
    } else if (isChat === "upload") {
      setIsMouseOver(true);
    } else if (isChat === "edit") {
      setIsEditmouseOver(true);
    }
  };

  const handleMouseOut = (isChat) => {
    if (isChat === "chat") {
      setIsChatMouseOver(false);
    } else if (isChat === "upload") {
      setIsMouseOver(false);
    } else if (isChat === "edit") {
      setIsEditmouseOver(false);
    }
  };

  // const [questionResults, setQuestionResults] = useState({});

  // useEffect(() => {
  //   console.log(questionResults);
  // }, [questionResults]);
  // useEffect(() => {
  //   setQuestionResults((prevResults) => {
  //     const updatedResults = { ...prevResults };

  //     answerDetails.forEach((validation) => {
  //       updatedResults[validation.question_id] = validation.result;
  //     });

  //     return updatedResults;
  //   });
  // }, [answerDetails]);
  const assessmentRef = useRef(null);

  useLayoutEffect(() => {
    if (assessmentRef.current && window.innerWidth <= 768) {
      setTimeout(() => {
        assessmentRef.current.scrollTop = -assessmentRef.current.scrollHeight;
      }, 1000);
    }
  }, [selectedTab === 1]);

  const [tableData, setTableData] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, item) => {
    if (currentItem.type === "Match the following"||currentItem.type === "Match the following with image") {
      setDraggedItem(item);
    }
  };
  const handleDrop = (e, rowIndex) => {
    e.preventDefault();

    // Ensure that the dragged item is not dropped on itself
    if (draggedItem) {
      // Update the table data with the dropped item
      const updatedTableData = [...answers];
      updatedTableData[rowIndex] = draggedItem;

      // Update the state
      setAnswers(updatedTableData);

      // Reset draggedItem state
      setDraggedItem(null);
    }
  };

  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  const data = {
    labels: ['Topic Completion', 'Assessment Completion'],
    datasets: [
      {
        label: 'Percentage',
        backgroundColor: ['#007bff', '#007bff'], // Blue and Green colors for bars
        data: graphData.map((item) => {
          if (item.topic_completion_percentage) {
            return item.topic_completion_percentage.y;
          } else if (item.assesments_completion_percentage) {
            return item.assesments_completion_percentage.y;
          }
        }),// Y values for Topic Completion and Assessment Completion respectively
        barThickness: 70,
      }
    ]
  };
  
  const options = {
    scales: {
      y: {
        type: 'linear',
        beginAtZero: true,
        max: 100, // Since the y values are percentages, set max value to 100
        min: 0,
        stepSize: 10, // Set the step size to 10 for clarity
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          callback: function(value) {
            return value + '%'; // Append '%' to the tick value
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  };


  useEffect(() => {
    if (currentItem && currentItem.time && peerRole === undefined) {
      const timeInSeconds = parseInt(currentItem.time) * 60; // Convert minutes to seconds
    setRemainingTime(timeInSeconds);
      setTimeout(() => {
        setTimerStarted(true);
      }, 1000);
    }
    setTimerExpired(false)
  }, [currentItem]);
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} `;
  };
  
//dictation audiuo
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

const handleAudioClick = (audioUrl,index) => {
  if (!audioPlayer || audioPlayer.src !== audioUrl) {
    const newAudioPlayer = new Audio(audioUrl);
    newAudioPlayer.play();
    setAudioPlayer(newAudioPlayer);
    setCurrentPlayingIndex(index);
  } else {
    if (audioPlayer.paused) {
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }
  }
};

//cross word puzzle

const [crosswordGrid, setCrosswordGrid] = useState([]);
const[cluesAcross,setCluesAcross]=useState([])
const[cluesDown,setCluesDown]=useState([])
const inputRefs = useRef([]);

useEffect(()=>{
  if (currentItem && currentItem.type === 'cross word puzzle') {
    const grid = currentItem.options.map(row => (
      row.map(cell => ({
        clueID:cell.clueID ||"",
        clueNumber:cell.clueNumber,
        cluevalue: '',
        disabled: cell.cluevalue === '',
      }))
    ));
    setAnswers(grid);
     // Extracting across and down clues
     const acrossClues = currentItem.left.map(clueObj => ({
      id: clueObj.id,
      number: clueObj.number,
      clue: clueObj.clue,
      answer:''
    }));
    const downClues = currentItem.right.map(clueObj => ({
      id: clueObj.id,
      number: clueObj.number,
      clue: clueObj.clue,
      answer:''
    }));

    setCluesAcross(acrossClues);
    setCluesDown(downClues);
  }

},[currentItem])


const handleCellValueChange = (rowIndex, colIndex, value, id, clueNumber) => {
  if (answers[rowIndex][colIndex].cluevalue !== '' && value === '') {
    return;
  }

  const newGrid = answers.map((row, i) =>
    row.map((cell, j) =>
      i === rowIndex && j === colIndex ? { ...cell, cluevalue: value.toUpperCase() } : cell
    )
  );
  setAnswers(newGrid);

  // Update the corresponding across clues if found
  const updatedCluesAcross = cluesAcross.map(clue => {
    if (clue.id === id) {
      const updatedAnswer = newGrid
        .flat()
        .filter(cell => cell.clueID === id)
        .map(cell => cell.cluevalue)
        .join("");
      return { ...clue, answer: updatedAnswer };
    } else {
      return clue;
    }
  });
  setCluesAcross(updatedCluesAcross);

  // Update the corresponding down clues if found
  const updatedCluesDown = cluesDown.map(clue => {
    if (clue.id === id) {
      const updatedAnswer = newGrid
        .flat()
        .filter(cell => cell.clueID === id)
        .map(cell => cell.cluevalue)
        .join("");
      return { ...clue, answer: updatedAnswer };
    } else {
      return clue;
    }
  });
  setCluesDown(updatedCluesDown);
  const currentCellIndex = rowIndex * answers[0].length + colIndex;
    let nextCellIndex = currentCellIndex + 1;
    while (nextCellIndex < answers.flat().length && answers.flat()[nextCellIndex].clueID !== id) {
      nextCellIndex++;
    }

    // If found, focus on the input of the next cell
    if (nextCellIndex < answers.flat().length) {
      const nextInputRef = inputRefs.current[nextCellIndex];
      if (nextInputRef && nextInputRef.focus) {
        nextInputRef.focus();
      }
    }
};

  

  return (
    <div className={style.Container}>
      {loading ? <Loader /> : ""}
      {lessonLoading ? (
        <div>
          {admin_Mapped_Lessons === true || admin_Mapped_Lessons === "true" ? (
            <div className={style.LoaderDiv}>
              <div className={style.Loadertext}>
                <p>Please Wait</p>
              </div>
              <div className={style.LoaderCircle}></div>
            </div>
          ) : (
            <div className={style.LoaderDiv}>
              <div className={style.Loadertext}>
                <p>
                  Please Wait <br /> AI generating your lesson
                </p>
              </div>
              <div className={style.LoaderCircle}></div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {uploadLoader ? (
        <div className={style.UploadLoaderDiv}>
          <div className={style.UploadLoaderText}>
            <p>Please Wait...</p>
          </div>
          <div className={style.UploadLoaderContent}>
            <div className={style.LoaderCircle}></div>
            <p>Preparing to upload... </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {validateLoader ? (
        <div className={style.validateLoaderDiv}>
          <div className={style.validateLoaderText}>
            <p>Please Wait...</p>
          </div>
          <div className={style.validateLoaderContent}>
            <div className={style.LoaderCircle}></div>
          </div>
        </div>
      ) : (
        ""
      )}
      {chatIcon && mentor !== "true" && peerRole === undefined ? (
        <div className={style.chatboticon}>
          <div className={style.Iconhead}>
            <span
              onClick={() => {
                setChatIcon(false);
              }}
            >
              <AiOutlineClose />
            </span>
          </div>
          {/* <div onClick={()=>{
        setPopup(true);
        setBot(true);
        chatRoom()
        setChatloader(true)
        setChatIcon(false)
      }}  className={style.icontext}>
        Hi <br/>I am Your Teacher Buddy!!!
      </div> */}
          <div
            onClick={() => {
              if (!lessonLoading) {
                setPopup(true);
                setBot(true);
                chatRoom();
                setChatloader(true);
                setChatIcon(false);
              }
            }}
            className={style.ChatImage}
          >
            <img src={chatLogoImage} />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {whiteBoradIconShow  ? (
        <div className={style.WhiteBoardboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )} */}

{/* {whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''} */}
      {assesmentError ? (
        <div className={style.POPUPOverlay}>
          <div className={style.AssesmentPopup}>
            <p>
              Something wen't Wrong
              <br />
              Please try again later....
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {popup ? (
        <>
          <div className={style.POPUPOverlay}></div>
          <div className={`${style.POPUP} ${closing ? style.closing : ""}`}>
            {bot ? (
              <div className={style.SummaryPopup}>
                {chatLoader ? (
                  <div className={style.Loaderchat}>
                    <Loader />
                  </div>
                ) : (
                  ""
                )}
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Smartee AI Tutor</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                        setLoading(false);
                        setBot(false);
                        setChatIcon(true);
                      }, 500);

                      setClosing(true);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>
                <div className={style.PopupContent} ref={containerRef}>
                  {messages.length > 0 &&
                    messages.map((message, index) => {
                      const isSenderMe = message.sender === "me";
                      return (
                        <div
                          key={index}
                          className={`${style.message} ${
                            isSenderMe ? style.sender : style.receiver
                          }`}
                        >
                          {message.content}
                        </div>
                      );
                    })}
                </div>
                <div className={style.TopicButtons}>
                  <button
                    disabled={chatLoader}
                    className={style.summarize}
                    onClick={() => {
                      chatSend("summarize");
                      setChatloader(true);
                    }}
                  >
                    Summarize to me
                  </button>
                  <button
                    disabled={chatLoader}
                    onClick={() => {
                      chatSend("explain");
                      setChatloader(true);
                    }}
                    className={style.summarize}
                  >
                    Explain to me
                  </button>
                  {/* <button className={style.summarize} onClick={() => {}}>
                      Upload My Lesson
                    </button> */}
                </div>
                <div className={style.BotInput}>
                  <div className={style.Input}>
                    <input
                      ref={inputRef}
                      value={chat}
                      onChange={(e) => {
                        setChat(e.target.value);
                      }}
                      onKeyDown={handleKeyPress}
                    />
                    {/* <span className={style.Upload}>
                            <BsEmojiSmile/>
                            <BiMicrophone />
                            <RiAttachment2 />
                          </span> */}
                  </div>
                  <IoSend
                    disabled={chat === ""}
                    className={style.Icon}
                    onClick={() => {
                      if (chat !== "") {
                        chatSendInput();
                        setChatloader(true);
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {uploadPopup ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.UploadPopUp}>
            {upload ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Upload My Lesson</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setUpload(false);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>
                <div className={style.UploadContent}>
                  <div className={style.uploddiv}>
                    <input
                      onClick={() => {
                        setAiupload(true);
                        setUpload(false);
                      }}
                      type="radio"
                      id="ai"
                      name="upload"
                    />
                    <label for="ai">Content Generated By AI</label>
                  </div>
                  <div className={style.uploddiv}>
                    <input
                      onClick={() => {
                        setCopyLesson(true);
                        setUpload(false);
                      }}
                      type="radio"
                      id="copy"
                      name="upload"
                    />
                    <label for="copy">Copy and paste lesson</label>
                  </div>
                  <div className={style.uploddiv}>
                    <input
                      onClick={() => {
                        setAttachmentLesson(true);
                        setUpload(false);
                      }}
                      type="radio"
                      id="attach"
                      name="upload"
                    />
                    <label for="attach">Upload lesson as attachment</label>
                  </div>

                  <div className={style.uploddiv}>
                    <input
                      onClick={() => {
                        setVideo(true);
                        setUpload(false);
                      }}
                      type="radio"
                      id="video"
                      name="upload"
                    />
                    <label for="video">Upload lesson as video </label>
                  </div>
                  <div className={style.uploddiv}>
                    <input
                      onClick={() => {
                        setUpload(false);
                        setPdfLesson(true);
                      }}
                      type="radio"
                      id="pdf"
                      name="upload"
                    />
                    <label for="pdf">Upload lesson as PDF</label>
                  </div>
                  {/* <div className={style.uploddiv}>
                      <input
                        onClick={() => {
                          setUpload(false)
                          setWordLesson(true)
                        }}
                        type="radio"
                        id="word"
                        name="upload"
                        
                      />
                      <label for="word">Upload lesson as Word</label>
                    </div> */}
                </div>
              </div>
            ) : (
              ""
            )}
            {copyLesson ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Upload My Lesson as Attachment</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setUpload(false);
                      setCopyLesson(false);
                      setEditorData("");
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>
                <div className={style.TitleInput}>
                  <input
                    value={learningTitle}
                    onChange={(e) => {
                      setLearningTitle(e.target.value);
                    }}
                    placeholder="Title here"
                  />
                </div>
                <div className={style.CopyContent}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    onChange={handleEditorChange}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "290px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                      alignment: {
                        options: ["left", "center", "right"],
                      },
                    }}
                  />
                </div>
                <div className={style.SaveButton}>
                  <button
                    disabled={editorData === ""}
                    onClick={() => {
                      textUpload();
                      setUploadPopup(false);
                      setUpload(false);
                      setCopyLesson(false);
                      setUploadLoader(true);
                    }}
                  >
                    Save Lesson
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            {attachmentLesson ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Upload My Lesson as Attachment</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setUpload(false);
                      setAttachmentLesson(false);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>

                <div className={style.attachmentContent}>
                  <div className={style.TitleInput}>
                    <input
                      value={learningTitle}
                      onChange={(e) => {
                        setLearningTitle(e.target.value);
                      }}
                      placeholder="Title here"
                    />
                  </div>
                  <div className={style.AttachmentInput}>
                    <div className={style.UploadInput}>
                      <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        accept="image/png, image/jpeg"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className={style.Button}>
                      <button
                        disabled={!image.data}
                        onClick={() => {
                          imageUpload();
                          setUploadPopup(false);
                          setUpload(false);
                          setAttachmentLesson(false);
                          setUploadLoader(true);
                        }}
                      >
                        Upload Lesson
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {aiupload ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Content Generated By AI</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setUpload(false);
                      setAiupload(false);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>

                <div className={style.aigenerate}>
                  <textarea
                    name=""
                    cols="30"
                    rows="10"
                    onChange={(e) => {
                      setAiText(e.target.value);
                    }}
                  ></textarea>

                  <div className={style.aigenerateButton}>
                    <button
                      onClick={() => {
                        aiUpload();
                        setAiupload(false);
                        setUpload(false);
                        setUploadLoader(true);
                        setUploadPopup(false);
                      }}
                      disabled={aitext === ""}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {video ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Upload Video </p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setUpload(false);
                      setVideo(false);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>

                <div className={style.aiContent}>
                  <div className={style.TitleInput}>
                    <input
                      value={learningTitle}
                      onChange={(e) => {
                        setLearningTitle(e.target.value);
                      }}
                      placeholder="Title here"
                    />
                  </div>
                  <div className={style.AttachmentInput}>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleVideoFileChange}
                    />
                    <div className={style.aiButton}>
                      <button
                        onClick={() => {
                          //  urlUpload()
                          videoUpload();
                          setVideo(false);
                          setUpload(false);
                          setUploadLoader(true);
                          setUploadPopup(false);
                        }}
                        disabled={selectedFile === ""}
                      >
                        Upload Video
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {pdfLesson ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Upload My Lesson as PDF</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setUpload(false);
                      setPdfLesson(false);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>

                <div className={style.attachmentContent}>
                  <div className={style.TitleInput}>
                    <input
                      value={learningTitle}
                      onChange={(e) => {
                        setLearningTitle(e.target.value);
                      }}
                      placeholder="Title here"
                    />
                  </div>
                  <div className={style.AttachmentInput}>
                    <div className={style.UploadInput}>
                      <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        accept="application/pdf"
                        onChange={handlePdfFileChange}
                      />
                    </div>
                    <div className={style.Button}>
                      <button
                        onClick={() => {
                          pdfUpload();
                          setUploadPopup(false);
                          setUpload(false);
                          setPdfLesson(false);
                          setUploadLoader(true);
                        }}
                      >
                        Upload Lesson
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {wordLesson ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Upload My Lesson as Word File</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setUpload(false);
                      setWordLesson(false);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>

                <div className={style.attachmentContent}>
                  <div className={style.TitleInput}>
                    <input
                      value={learningTitle}
                      onChange={(e) => {
                        setLearningTitle(e.target.value);
                      }}
                      placeholder="Title here"
                    />
                  </div>
                  <div className={style.AttachmentInput}>
                    <div className={style.UploadInput}>
                      <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      />
                    </div>
                    <div className={style.Button}>
                      <button disabled={!image.data} onClick={() => {}}>
                        Upload Lesson
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {editLesson ? (
              <div className={style.SummaryPopup}>
                <div className={style.popupHead}>
                  <div className={style.HeadText}>
                    <p>Edit Lesson</p>
                  </div>
                  <div
                    className={style.PopupIcon}
                    onClick={() => {
                      setUploadPopup(false);
                      setEditLesson(false);
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>

                <div className={style.CopyContent}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={lessoneditData}
                    onChange={handlelessonEditorChange}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "290px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                      alignment: {
                        options: ["left", "center", "right"],
                      },
                    }}
                  />
                </div>
                <div className={style.SaveButton}>
                  <button
                    disabled={lessoneditData === ""}
                    onClick={() => {
                      lessonEdit();
                      setLoading(true);
                      setUploadPopup(false);
                      setEditLesson(false);
                    }}
                  >
                    Update Lesson
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}

      <div>
        <div className={style.topicHead}>
          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: "#1a1e5d " } }}
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              "@media (max-width: 400px)": {
                minHeight: "fit-content",
              },
              "@media (max-width: 500px)": {
                padding: "5px",
                minHeight: "fit-content",
              },
            }}
          >
            <Tab
              label="Lessons"
              sx={{
                "&.Mui-selected": {
                  // color: "white",
                  // backgroundColor: "#1a1e5d",
                  fontWeight: "bold",
                },
                "@media (max-width: 500px)": {
                  fontSize: "0.8rem",
                  padding: "5px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                "@media (max-width: 400px)": {
                  padding: "5px 6px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                fontSize: "1rem",
                color: "#1a1e5d !important",
                // backgroundColor: "#dbdbdb",

                margin: "0 .5rem",
                borderRadius: "10px",
              }}
            />
            <Tab
              label="Assessments"
              sx={{
                "&.Mui-selected": {
                  // color: "white",
                  // backgroundColor: "#1a1e5d",
                  fontWeight: "bold",
                },
                "@media (max-width: 500px)": {
                  fontSize: "0.8rem",
                  padding: "5px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                "@media (max-width: 400px)": {
                  padding: "5px 6px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                fontSize: "1rem",
                color: "#1a1e5d !important",
                // backgroundColor: "#dbdbdb",

                margin: "0 .5rem",
                borderRadius: "10px",
              }}
              onClick={() => {
                if (!lessonLoading) {
                  assesmentDetails();
                  setChatIcon(true);
                }
              }}
            />
            <Tab
              label="Activities"
              sx={{
                "&.Mui-selected": {
                  // color: "white",
                  // backgroundColor: "#1a1e5d",
                  fontWeight: "bold",
                },
                "@media (max-width: 500px)": {
                  fontSize: "0.8rem",
                  padding: "5px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                "@media (max-width: 400px)": {
                  padding: "5px 6px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                fontSize: "1rem",
                color: "#1a1e5d !important",
                // backgroundColor: "#dbdbdb",

                margin: "0 .5rem",
                borderRadius: "10px",
              }}
              onClick={() => {
                if (!lessonLoading) {
                  activityGet();
                  setLoading(true);
                  setChatIcon(true);
                }
              }}
            />
            {/* <Tab
              label="Labs"
              sx={{
                "&.Mui-selected": {
                  // color: "white",
                  // backgroundColor: "#1a1e5d",
                  fontWeight: "bold",
                },
                "@media (max-width: 500px)": {
                  fontSize: "0.8rem",
                  padding: "5px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                "@media (max-width: 400px)": {
                  padding: "5px 6px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                fontSize: "1rem",
                color: "#1a1e5d !important",
                // backgroundColor: "#dbdbdb",

                margin: "0 .5rem",
                borderRadius: "10px",
              }}
              onClick={()=>{
                labsGet();
              }}
            /> */}

            <Tab
              label="Insights"
              sx={{
                "&.Mui-selected": {
                  // color: "white",
                  // backgroundColor: "#1a1e5d",
                  fontWeight: "bold",
                },
                "@media (max-width: 500px)": {
                  fontSize: "0.8rem",
                  padding: "5px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                "@media (max-width: 400px)": {
                  padding: "5px 6px",
                  minHeight: "fit-content",
                  margin: "0",
                  minWidth: "fit-content",
                },
                fontSize: "1rem",
                color: "#1a1e5d !important",
                // backgroundColor: "#dbdbdb",

                margin: "0 .5rem",
                borderRadius: "10px",
              }}
              onClick={() => {
                analyticsGet();
                getLearningLevel()
                assesmentSummaryDetails()
              }}
            />
          </Tabs>
          {/* <div className={style.HeaderIcons}>
            <FiPower />
          </div> */}
        </div>

        <Typography className={style.Tab}>
          {selectedTab === 0 && (
            <div className={style.LessonDiv}>
              <div className={style.LessonHead}>
                <p>{topicName}</p>
                <div className={style.SecondHead}>
                  <div className={style.HeaderIcon}>
                    {mentor === "true" ? (
                      <>
                        <div
                          className={style.BotImage}
                          onMouseOver={() => handleMouseOver("upload")}
                          onMouseOut={() => handleMouseOut("upload")}
                          onClick={() => {
                            if (!lessonLoading && !loading) {
                              setUploadPopup(true);
                              setUpload(true);
                            }
                          }}
                        >
                          {" "}
                          <div
                            className={style.UploadText}
                            style={{ display: isMouseOver ? "block" : "none" }}
                          >
                            Upload
                          </div>{" "}
                          <img src={UploadLogo} />
                        </div>
                        <div
                          disabled={type === "image" || type === "video_url"}
                          className={style.BotImage}
                          onMouseOver={() => handleMouseOver("edit")}
                          onMouseOut={() => handleMouseOut("edit")}
                          onClick={() => {
                            if (!lessonLoading && !loading) {
                              setUploadPopup(true);
                              setEditLesson(true);
                            }
                            // setLessonEditData(`<pre>${topicDetail}</pre>`)
                          }}
                        >
                          {" "}
                          <div
                            className={style.UploadText}
                            style={{
                              display: isEditMouseOver ? "block" : "none",
                            }}
                          >
                            Edit
                          </div>
                          <img src={EditImage} />
                        </div>
                      </>
                    ) : peerRole === undefined ? (
                      <>
                        {/* <BiFile /> */}
                        {!chatIcon ? (
                          <div
                            className={style.teacherImage}
                            onMouseOver={() => handleMouseOver("chat")}
                            onMouseOut={() => handleMouseOut("chat")}
                            onClick={() => {
                              if (!lessonLoading && !loading) {
                                setPopup(true);
                                setBot(true);
                                chatRoom();
                                setChatloader(true);
                              }
                            }}
                          >
                            <div
                              className={style.UploadText}
                              style={{
                                display: isChatMouseOver ? "block" : "none",
                              }}
                            >
                              Chat Bot
                            </div>{" "}
                            <img src={FloatingIcon} />
                          </div>
                        ) : (
                          ""
                        )}

                        <div
                          className={style.BotImage}
                          onMouseOver={() => handleMouseOver("upload")}
                          onMouseOut={() => handleMouseOut("upload")}
                          onClick={() => {
                            if (!lessonLoading && !loading) {
                              setUploadPopup(true);
                              setUpload(true);
                            }
                          }}
                        >
                          <div
                            className={style.UploadText}
                            style={{ display: isMouseOver ? "block" : "none" }}
                          >
                            Upload
                          </div>
                          <img src={UploadLogo} />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={style.Pagination}>
                    <button
                      disabled={current === 1 || lessonLoading || loading}
                      onClick={() => {
                        decrement();
                        setLoading(true);
                      }}
                    >
                      <GrFormPrevious />
                    </button>
                    <button>
                      {current}/{noofpages}
                    </button>
                    <button
                      disabled={
                        current === noofpages || lessonLoading || loading
                      }
                      onClick={() => {
                        increment();
                        setLoading(true);
                      }}
                    >
                      <GrFormNext />
                    </button>
                  </div>
                </div>
              </div>
              <div className={style.topicDetal}>
                {type === "image" ? (
                  <div>
                    {imageUrl ? (
                      <div div className={style.TopicDetailtext}>
                        <span>{imageUrl.title ? imageUrl.title : ""}</span>
                        <div className={style.imageView}>
                          <img src={imageUrl.text} />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : type === "video_url" ? (
                  <div>
                    {videourl ? (
                      <div className={style.TopicDetailtext}>
                      <span>{videourl.title ? videourl.title : ""}</span>
                      <video key={videourl.text} controls width="100%" height="auto">
                        <source src={videourl.text} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : type === "pdf" ? (
                  <div>
                    {pdfUrl ? (
                      <div div className={style.TopicDetailtext}>
                        <span>{pdfUrl.title ? pdfUrl.title : ""}</span>
                        <div>
                          <iframe
                            title="PDF Viewer"
                            src={pdfUrl.text}
                            width="100%"
                            height="560px"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <>
                    {topicDetail ? (
                      <div className={style.TopicDetailtext}>
                        <span>
                          {topicDetail.title ? topicDetail.title : ""}
                        </span>
                        {topicDetail.text && (
                          <div>
                            {topicDetail.text.split("\n").map((text, index) => (
                              <p
                                key={index}
                                className={
                                  text ? style.TextContent : style.Blank
                                }
                              >
                                {text ? (
                                  <span
                                    dangerouslySetInnerHTML={{ __html: text }}
                                  />
                                ) : (
                                  "Blank"
                                )}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div ref={assessmentRef} className={style.assesment}>
              {currentItem.id ? (
                <div className={style.AssessmentHead}>
                  <div className={style.totalAssesment}>
                    <div className={style.Count}>
                      <span className={style.value}>
                        {" "}
                        Assessment no -{currentItem.id}/{array.length}
                      </span>
                    </div>
                    <div
                      className={style.assesmentQuestion}
                      style={{
                        alignItems:
                          currentItem.type === "fill in the blanks"
                            ? "normal"
                            : "",
                      }}
                    >
                      {currentItem.id}{" "}.&nbsp;
                      {currentItem.type === "fill in the blanks" &&
                      currentItem.generation === "admin" ? (
                        <div className={style.FillQuestion}>
                          {currentItem.question
                            .split(/\[blank \d+\]/)
                            .map((part, index) =>
                              index ===
                              currentItem.question.split(/\[blank \d+\]/)
                                .length -
                                1 ? (
                                <span
                                  key={index}
                                  dangerouslySetInnerHTML={{ __html: part }}
                                />
                              ) : (
                                <React.Fragment key={index}>
                                  <span>{part}</span>
                                  <input
                                    type="text"
                                    value={answers[index] || ""}
                                    onChange={(e) => {
                                      const newAnswers = [...answers];
                                      newAnswers[index] = e.target.value;
                                      setAnswers(newAnswers);
                                    }}
                                    disabled={
                                      timerExpired ||
                                      isTextareaDisabled ||
                                      peerRole !== undefined
                                    }
                                  />
                                </React.Fragment>
                              )
                            )}
                        </div>
                      ) : currentItem.type === "fill in the blanks" ? (
                        <div className={style.FillQuestion}>
                          {currentItem.question
                            .split(/_{2,}/)
                            .map((part, index) =>
                              index ===
                              currentItem.question.split(/_{2,}/).length - 1 ? (
                                <span
                                  key={index}
                                  dangerouslySetInnerHTML={{ __html: part }}
                                />
                              ) : (
                                <React.Fragment key={index}>
                                  <span>{part}</span>
                                  <input
                                    type="text"
                                    value={answers[index] || ""}
                                    onChange={(e) => {
                                      const newAnswers = [...answers];
                                      newAnswers[index] = e.target.value;
                                      setAnswers(newAnswers);
                                    }}
                                    disabled={
                                      timerExpired ||
                                      isTextareaDisabled ||
                                      peerRole !== undefined
                                    }
                                  />
                                </React.Fragment>
                              )
                            )}
                        </div>
                      ) : currentItem.type === "Match the following" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentItem.question,
                          }}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentItem.question,
                          }}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        border:
                          currentItem.type === "multiple choices" ||
                          currentItem.type === "multiple choice" ||
                          currentItem.type === "Match the following" ||
                          (currentItem.type === "fill in the blanks" &&
                            currentItem.question.includes("__") &&
                            currentItem.options.length < 0)
                            ? "none"
                            : "",
                      }}
                      className={style.assesmentAnswer}
                    >
                      {
                      currentItem.type === "multiple choices" ||
                      currentItem.type === "multiple choice" ? (
                        currentItem.options.map((optionObj, index) => {
                          let optionValue;
                          if (typeof optionObj === 'string') {
                            optionValue = optionObj; // Direct string value
                          } else if (optionObj.option) {
                            optionValue = optionObj.option; // Nested within an object with "option" key
                          } else {
                            // If options are nested within objects with unique keys
                            const optionKey = Object.keys(optionObj)[0];
                            optionValue = optionObj[optionKey];
                          }
                          return (
                            <div key={index}>
                              <input
                                type="radio"
                                id={`option_${index}`}
                                name="assessmentOptions"
                                value={optionValue}
                                checked={answers.includes(optionValue)}
                                onChange={(e) => {
                                  setAnswers([e.target.value]);
                                }}
                                disabled={
                                  timerExpired ||
                                  isTextareaDisabled ||
                                  peerRole !== undefined
                                }
                              />
                              <label htmlFor={`option_${index}`}>{optionValue}</label>
                            </div>
                          );
                        })
                      ) 
                      : 
                      currentItem.type === "fill in the blanks" &&
                        currentItem.question.includes("_") ? (
                        <></>
                      ) : currentItem.type === "fill in the blanks" &&
                        !currentItem.question.includes("_") &&
                        currentItem.generation === "admin" ? (
                        <></>
                      ) : currentItem.type === "fill in the blanks" &&
                        !currentItem.question.includes("_") &&
                        currentItem.generation !== "admin" ? (
                        <>
                          <textarea
                            onClick={handleTextareaClick}
                            onChange={(e) => {
                              setAnswers([e.target.value]);
                            }}
                            value={answers[0]}
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            disabled={
                              timerExpired ||
                              isTextareaDisabled ||
                              peerRole !== undefined
                            }
                          ></textarea>
                          <div className={style.BottomIcons}></div>
                        </>
                      ) : currentItem.type === "Match the following" ? (
                        <div className={style.DropContainer}>
                          <div className={style.DropQuestions}>
                            <table>
                              <tbody>
                                {currentItem.left
                                  ? currentItem.left.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{item}</td>
                                          <td
                                            onDragOver={(e) =>
                                              e.preventDefault()
                                            }
                                            onDrop={(e) => handleDrop(e, index)}
                                          >
                                            {answers[index] ? (
                                              <div
                                                className={style.DraggableItem}
                                              >
                                                {answers[index]}
                                              </div>
                                            ) : (
                                              <div
                                                className={
                                                  style.DraggablePlaceholder
                                                }
                                              >
                                                {/* {draggedItem === null &&
                                                  "Drag Answer Here"} */}
                                                  {"Drag Answer Here"}
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div className={style.DropAnswers}>
                            <h5>Answers</h5>
                            <div className={style.CardContainer}>
                              {currentItem.right
                                ? currentItem.right.map((item, index) => {
                                    return (
                                      <div
                                        disabled={timerExpired ||peerRole !== undefined}
                                        key={index}
                                        draggable={!peerRole}
                                        onDragStart={(e) =>
                                          handleDragStart(e, item)
                                        }
                                        className={style.Card}
                                      >
                                        <p>{item} </p>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
                      ):currentItem.type==="dictation"?(
                        <div >
                          <table className={style.DictationTabel}>
                            <tbody>
                              {currentItem.left.map((url,index)=>{
                                return(
                                  <tr key={index}>
                                <td><HiSpeakerWave style={{color:currentPlayingIndex===index?"green":''}} className={style.speakerIcon} onClick={() => handleAudioClick(url,index)}/></td>
                                <td><input
                                    type="text"
                                    placeholder="Enter your answer"
                                    value={answers[index] || ""}
                                    onChange={(e) => {
                                      const newAnswers = [...answers];
                                      newAnswers[index] = e.target.value;
                                      setAnswers(newAnswers);
                                    }}
                                    disabled={
                                      timerExpired ||
                                      isTextareaDisabled ||
                                      peerRole !== undefined
                                    }
                                  /></td>
                              </tr>
                                )

                              })}
                              
                            </tbody>
                          </table>
                        </div>
                      )
                       : currentItem.type === "Match the following with image" ? (
                        <div className={style.DropContainer}>
                          <div className={style.DropQuestions}>
                            <table>
                              <tbody>
                                {currentItem.left
                                  ? currentItem.left.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td><div className={style.DropDownImage}>
                                          <img src={item} alt={`Option ${index + 1}`} style={{ width: "100px", height: "100px" }} />
                                            </div></td>
                                          <td
                                            onDragOver={(e) =>
                                              e.preventDefault()
                                            }
                                            onDrop={(e) => handleDrop(e, index)}
                                          >
                                            {answers[index] ? (
                                              <div
                                                className={style.DraggableItem}
                                              >
                                                {answers[index]}
                                              </div>
                                            ) : (
                                              <div
                                                className={
                                                  style.DraggablePlaceholder
                                                }
                                              >
                                                {/* {draggedItem === null &&
                                                  "Drag Answer Here"} */}
                                                  {"Drag Answer Here"}
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div className={style.DropAnswers}>
                            <h5>Answers</h5>
                            <div className={style.CardContainer}>
                              {currentItem.right
                                ? currentItem.right.map((item, index) => {
                                    return (
                                      <div
                                        disabled={timerExpired ||peerRole !== undefined}
                                        key={index}
                                        draggable={!peerRole}
                                        onDragStart={(e) =>
                                          handleDragStart(e, item)
                                        }
                                        className={style.Card}
                                      >
                                        <p>{item} </p>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
                      )
                      : currentItem.type === "cross word puzzle"? (
                        <div className={style.crosswordcontainer}>
                        <div className={style.crosswordpuzzle}>
                        {answers.map((row, rowIndex) => (
                          <div key={rowIndex} className={style.row}>
                            {row.map((cell, colIndex) => (
                              <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                                <input
                                ref={(input) => (inputRefs.current[rowIndex * answers[0].length + colIndex] = input)}
                                  type="text"
                                  maxLength="1"
                                  className={`${style.cell} ${cell.disabled ? style.disabledInput : ''}`}
                                  value={cell.value}
                                  onChange={(e) => handleCellValueChange(rowIndex, colIndex, e.target.value,cell.clueID,cell.clueNumber)}
                                  disabled={cell.disabled}
                                />
                                {cell.clueNumber && (  // Display clue number only if it exists
                                  <div className={style.clueNumber}>{cell.clueNumber}</div>
                                )}
                              </div>
                            ))}
                          </div>
                          ))}
                      </div>
                      <div className={style.PuzzleQuestion}>
                        <div className={style.QuestionAcross}>
                        <h4>Across</h4>
                        {cluesAcross.map((clueObj, index) => (
                          <div key={index} className={style.QuestionAcrossInputContainer}>
                            <span>{clueObj.number}.{clueObj.clue} </span>
                            
                            <input type="text" value={clueObj.answer} readOnly/>
                          </div>
                        ))}
                        
                        </div>
                        <div className={style.QuestionDown}>
                        <h4>Down</h4>
                        {cluesDown.map((clueObj, index) => (
                            <div key={index} className={style.QuestionDownInputContainer}>
                              <span>{clueObj.number}.{clueObj.clue} </span>
                              <input type="text" value={clueObj.answer} readOnly/>
                              
                            </div>
                          ))}
                          
                          </div>
                      </div>
                        </div>
                      )
                      : (
                        <>
                          <textarea
                            onClick={handleTextareaClick}
                            onChange={(e) => {
                              setAnswers([e.target.value]);
                            }}
                            value={answers[0]}
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            disabled={
                              timerExpired ||
                              isTextareaDisabled ||
                              peerRole !== undefined
                            }
                          ></textarea>
                        </>
                      )}
                    </div>
                    <div className={style.BottomIcons}>
                            <span>
                              {timerStarted && !timerExpired && (
                                <p>Time Remaining:  {formatTime(remainingTime)} seconds</p>
                              )}
                              {timerExpired && (
                                <p style={{ color: "red" }}>Timer Expired!</p>
                              )}
                            </span>
                          </div>

                    <div className={style.answer}>
                      {answerDetails.length > 0 &&
                        answerDetails.map((validation) => {
                          const resultStyle = {
                            color:
                              validation.answer_is === "Correct"
                                ? "green"
                                : "red",
                          };
                          return (
                            <p key={validation.question_id} style={resultStyle}>
                              {validation.result}
                            </p>
                          );
                        })}
                    </div>
                    {validatClicked?
                    <div className={style.explanation}>
                      <h4>Explanation</h4>
                          <p
                          dangerouslySetInnerHTML={{
                            __html: currentItem.question_explained,
                          }}
                        />
                          </div>
                        :""}
                    {valid ? (
                      <p className={style.Validtext}>
                        Please Enter Your Answer
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={style.AddButton}>
                    <>
                      <button
                        className={style.ValidateButton}
                        onClick={() => {
                          if (answers.length > 0) {
                            if (peerRole === undefined) {
                              handleCheckCircleClick();
                              setValidateClicked(true);
                            }
                          } else {
                            setValid(true);
                          }
                        }}
                        disabled={validatClicked||timerExpired || peerRole !== undefined}
                      >
                        Validate
                      </button>
                      {currentIndex === array.length - 1 ? (
                        ""
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              handleNext();
                            }}
                            disabled={currentIndex === array.length - 1}
                            className={style.FinishButton}
                          >
                            Next Question
                          </button>
                          <button
                            onClick={() => {
                              handleSkip();
                            }}
                            disabled={
                              currentIndex === array.length - 1 ||
                              peerRole !== undefined
                            }
                            className={style.SkipButton}
                          >
                            Skip
                          </button>
                        </>
                      )}
                    </>
                    {currentIndex === array.length - 1 ? (
                      <>
                        <button
                          onClick={() => {
                            const source =
                              peerRole !== undefined ? "support" : "";
                            navigate("/answers", {
                              state: {
                                topicID: Topic.id,
                                peerRole: peerRole,
                                source,
                                aiId:AiId
                              },
                            });
                          }}
                          disabled={peerRole !== undefined}
                          className={style.FinishButton}
                        >
                          Finish My Assessment
                        </button>
                        <button
                          onClick={() => {
                            assesmentMore();
                            setLoading(true);
                          }}
                          className={style.SkipButton}
                          disabled={peerRole !== undefined}
                        >
                          Add More Assessment
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {loading ? (
                    ""
                  ) : (
                    <div className={style.previousAnswer}>
                      <h3>Assessment History</h3>
                      <div className={style.previousAnswerContent}>
                        {answerComment && answerComment.length > 0
                          ? answerComment.map((answer, index) => {
                              const date = new Date(
                                answer.CreatedTimestamp?.$date
                              );
                              const formattedDate = date.toLocaleString();
                              const answers = answer.answer && answer.answer.length > 0 ? answer.answer.join(",") : "";
                              const isNewFormat = answer.answer && answer.answer.length && Array.isArray(answer.answer[0]);
                              const groupByClueID = (arr) => {
                                const grouped = {};
                                arr.forEach((clues) => {
                                  clues.forEach((clue) => {
                                    if (clue.clueID in grouped) {
                                      grouped[clue.clueID].push(clue.cluevalue);
                                    } else {
                                      grouped[clue.clueID] = [clue.cluevalue];
                                    }
                                  });
                                });
                                return grouped;
                              };
                              return (
                                <div key={index} className={style.AnswerList}>
                                  <div className={style.AnswerSend}>
                                    <div className={style.AnswerIcon}>
                                      <span className={style.IconText}>
                                        {answer.user_name?.charAt(0)}
                                      </span>
                                    </div>
                                    <div className={style.AnswerText}>
                                      <p>
                                        <strong>{answer.user_name}</strong>{" "}
                                        <span style={{ fontSize: "12px" }}>
                                          {formattedDate}
                                        </span>
                                      </p>
                                      {isNewFormat ? (
                                    <div>
                      {Object.entries(groupByClueID(answer.answer)).map(([clueID, values]) => (
                        <p key={clueID}>
                          {values} 
                        </p>
                      ))}
                    </div>
                                        ) : (
                                          // Render old format
                                          <p>Your Answer : {answers}</p>
                                        )}
                                    </div>
                                  </div>
                                  <div className={style.AnswerReplay}>
                                    <div className={style.AnswerIcon}>
                                      <span style={{ background: "#d7bb95" }}>
                                        AI
                                      </span>
                                    </div>
                                    <div className={style.AnswerText}>
                                      <p>
                                        <strong>{answer.valuated_by}</strong>{" "}
                                      </p>
                                      <p>{answer.result}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : "No Answer"}
                      </div>
                    </div>
                  )}
                  <div className={style.AssesmentComents}>
                    <h3>Assessment Comments</h3>
                    <div className={style.AssesmentComentsInput}>
                      <div onClick={() => {}} className={style.CommentEditor}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={assessmentCommentText}
                          disabled={(mentor==="true"&&currentItem.comment_by_mentor===false)||(mentor==="false" && currentItem.comment_by_learner===false && peerRole!==undefined)}
                          onChange={(event, editor) =>
                            assessmentCommentEditorChange(event, editor)
                          }
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "100px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                          config={{
                            toolbar: [
                              "",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                            ],
                            placeholder: "Write a comment",
                            alignment: {
                              options: ["left", "center", "right"],
                            },
                          }}
                        />
                      </div>
                      <div className={style.CommentButton}>
                        <button
                          onClick={() => {
                            if (assessmentCommentText !== "") {
                              assessmentCommentAdd();
                            }
                          }}
                        >
                          Post
                        </button>
                      </div>
                      <div className={style.CommentDisplay}>
                        {assesmentComentData[0] &&
                        assesmentComentData[0].comments &&
                        assesmentComentData[0].comments.length > 0 ? (
                          assesmentComentData[0].comments.map(
                            (comment, commentIndex) => {
                              const commentTime = new Date(comment.time.$date);
                              const year = commentTime.getFullYear();
                              const month = commentTime.getMonth() + 1;
                              const day = commentTime.getDate();
                              const hours = commentTime.getHours();
                              const minutes = commentTime.getMinutes();
                              const seconds = commentTime.getSeconds();
                              const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                              function parseAndModifyLinks(html) {
                                const parser = new DOMParser();
                                const doc = parser.parseFromString(
                                  html,
                                  "text/html"
                                );

                                const anchorTags = doc.querySelectorAll("a");
                                anchorTags.forEach((a) => {
                                  a.setAttribute("target", "_blank");
                                });

                                return doc.body.innerHTML;
                              }

                              return (
                                <div
                                  key={commentIndex}
                                  className={style.CommentList}
                                >
                                  <div className={style.profileIcon}>
                                    <span>
                                      {comment.uploaded_by.name.charAt(0)}
                                    </span>
                                  </div>
                                  <div className={style.CommentText}>
                                    <p>
                                      <strong>
                                        {comment.uploaded_by.name}
                                      </strong>{" "}
                                      <span style={{ fontSize: "12px" }}>
                                        {formattedDateTime}
                                      </span>
                                    </p>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: parseAndModifyLinks(
                                          comment.content
                                        ),
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <p>No comments yet</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>No Assessments in mapped yet </div>
              )}

              <div className={style.AssesssmentSide}>
                <div className={style.Sidecard}>
                  <div className={style.CardHead}>Question Palette:</div>
                  <div className={style.Set}>
                    <p>Set :1</p>
                  </div>
                  <div className={style.Grid}>
                    {numbers.map((number) => {
                      // const validation = answerDetails.find(
                      //   (item) => item.question_id === number
                      // );
                      // const backgroundColor =
                      //   validation && validation.answer_is === "Correct" ? "green" : "red";

                      return (
                        <span
                        style={
                          number === selectedNumber // Apply selection styles
                            ? {
                                border: "2px solid #a0d77c",
                                color: "#57aa3f",
                                background: "#e0fbdb",
                              } // Emphasize selected button
                            :{}
                        }
                          key={number}
                          onClick={() => {
                            setAnswers([]);
                            setCurrentIndex(number - 1);
                            setSelectedNumber(number)
                            clearTimeout(Timer);
                            setTimerExpired(false);
                            setTimerStarted(false);
                            setIsTextareaDisabled(false);
                            setAnswerDetails([]);
                            setAnswer("");
                            setValidateClicked(false);
                          }}
                          className={style.span}
                          // style={{ backgroundColor }}
                          disabled={isTimerRunning}
                        >
                          {number}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div  onClick={() => {
                            const source =
                              peerRole !== undefined ? "support" : "";
                            navigate("/answers", {
                              state: {
                                topicID: Topic.id,
                                peerRole: peerRole,
                                source,
                                aiId:AiId
                              },
                            });
                          }} className={style.AssessmentSummary}>
                  <h4>Assessment Summary</h4>
                <div className={style.Marks}>
          
                <>
            <div style={{background:"#007bff"}} className={style.Markdiv}> 
            <div className={style.FirstText}>Total Mark</div>:&nbsp;
            <div className={style.SecondText}>{obtainedMark||0}/{totalMark||0}</div>
            </div>
            <div style={{background:"#007bff"}} className={style.Markdiv}>  <div className={style.FirstText}>Correct Answer</div>:&nbsp;
            <div className={style.SecondText}>{correct||0}</div>  </div>
           <div style={{background:"#e63720"}} className={style.Markdiv}><div className={style.FirstText}>Wrong Answer</div>:&nbsp;
            <div className={style.SecondText}>{inCorrect||0}</div>  </div>
           <div style={{background:"#c2af04"}} className={style.Markdiv}> <div className={style.FirstText}>Unattended</div>:&nbsp;
            <div className={style.SecondText}>{unAttended||0}</div></div>
          </>
          
        </div>
                </div>
              </div>
              {validationSuccess ? (
                <div
                  className={style.Overlay}
                  onClick={() => {
                    setValidationSuccess(false);
                  }}
                >
                  <div className={style.assessmentSuccessAnimation}>
                    <Lottie
                      animationData={AnimationData.success}
                      loop={false}
                      duration
                      autoplay
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {validationFail ? (
                <div
                  className={style.Overlay}
                  onClick={() => {
                    setValidationFail(false);
                  }}
                >
                  <div className={style.assessmentFailAnimation}>
                    <Lottie
                      animationData={AnimationData.fail}
                      loop={false}
                      duration
                      autoplay
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {selectedTab === 2 && (
            <div className={style.Practise}>
              {deleteActivity ? (
                <div className={style.DeletePopup}>
                  <div className={style.DeletePopupText}>
                    <p>Are you sure you want to Delete?</p>
                  </div>
                  <div className={style.DeletePopupButton}>
                    <button
                      onClick={(e) => {
                        activityDelete();
                        setLoading(true);
                        setDeleteActivity(false);
                      }}
                    >
                      YES
                    </button>
                    <button
                      onClick={() => {
                        setDeleteActivity(false);
                      }}
                    >
                      NO
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              {activityPopup ? (
                <>
                  <div className={style.Overlay}></div>
                  <div className={style.ActivityPopup}>
                    {activitySelect ? (
                      <div className={style.SummaryPopup}>
                        <div className={style.popupHead}>
                          <div className={style.HeadText}>
                            <p>Add Activity</p>
                          </div>
                          <div
                            className={style.PopupIcon}
                            onClick={() => {
                              setActivityPopup(false);
                              setActivitySelect(false);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>
                        <div className={style.UploadContent}>
                          <div className={style.uploddiv}>
                            <input
                              onClick={() => {
                                setCopyActivity(true);
                                setActivitySelect(false);
                              }}
                              type="radio"
                              id="copy"
                              name="upload"
                            />
                            <label for="copy">Copy and paste Task</label>
                          </div>
                          <div className={style.uploddiv}>
                            <input
                              onClick={() => {
                                setAttachmentActivity(true);
                                setActivitySelect(false);
                              }}
                              type="radio"
                              id="attach"
                              name="upload"
                            />
                            <label for="attach">Upload Task as Image</label>
                          </div>
                          <div className={style.uploddiv}>
                            <input
                              onClick={() => {
                                setVideoActivity(true);
                                setActivitySelect(false);
                              }}
                              type="radio"
                              id="video"
                              name="upload"
                            />
                            <label for="video">Upload Task as video </label>
                          </div>
                          <div className={style.uploddiv}>
                            <input
                              onClick={() => {
                                setActivityUrl(true);
                                setActivitySelect(false);
                              }}
                              type="radio"
                              id="url"
                              name="upload"
                            />
                            <label for="url">Upload Task as Url </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {copyActivity ? (
                      <div className={style.SummaryPopup}>
                        <div className={style.popupHead}>
                          <div className={style.HeadText}>
                            <p>Type or copy and paste the exercise below :</p>
                          </div>
                          <div
                            className={style.PopupIcon}
                            onClick={() => {
                              setActivityPopup(false);
                              setActivitySelect(false);
                              setCopyActivity(false);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>

                        <div className={style.CopyContent}>
                          <div className={style.TitleInput}>
                            <input
                              onChange={(e) => {
                                setActivityTitle(e.target.value);
                              }}
                              placeholder="Title here"
                            />
                          </div>
                          <CKEditor
                            editor={ClassicEditor}
                            data={activityTextData}
                            onChange={handleactivityEditorChange}
                            onReady={(editor) => {
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  "height",
                                  "290px",
                                  editor.editing.view.document.getRoot()
                                );
                              });
                            }}
                            config={{
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                              ],
                              alignment: {
                                options: ["left", "center", "right"],
                              },
                            }}
                          />
                        </div>
                        <div className={style.SaveButton}>
                          <button
                            disabled={activityTextData === ""}
                            onClick={() => {
                              activityTextUpload("text");
                              setUploadLoader(true);
                              setActivityPopup(false);
                              setCopyActivity(false);
                            }}
                          >
                            Save Exercise
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {attachmentActivity ? (
                      <div className={style.SummaryPopup}>
                        <div className={style.popupHead}>
                          <div className={style.HeadText}>
                            <p>Task Attachment</p>
                          </div>
                          <div
                            className={style.PopupIcon}
                            onClick={() => {
                              setActivityPopup(false);
                              setActivitySelect(false);
                              setAttachmentActivity(false);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>

                        <div className={style.urlContent}>
                          <div className={style.TitleInput}>
                            <input
                              onChange={(e) => {
                                setActivityTitle(e.target.value);
                              }}
                              placeholder="Title here"
                            />
                          </div>
                          <div className={style.urlInput}>
                            <div className={style.UploadInput}>
                              <input
                                type="file"
                                id="avatar"
                                name="avatar"
                                accept="image/png, image/jpeg"
                                onChange={handleActivityFileChange}
                              />
                            </div>
                            <div className={style.urlButton}>
                              <button
                                onClick={() => {
                                  activityImageUpload();
                                  setActivityPopup(false);
                                  setAttachmentActivity(false);
                                  setUploadLoader(true);
                                }}
                              >
                                Save Task
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {videoActivity ? (
                      <div className={style.SummaryPopup}>
                        <div className={style.popupHead}>
                          <div className={style.HeadText}>
                            <p>Task Video </p>
                          </div>
                          <div
                            className={style.PopupIcon}
                            onClick={() => {
                              setActivityPopup(false);
                              setActivitySelect(false);
                              setVideoActivity(false);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>

                        <div className={style.urlContent}>
                          <div className={style.TitleInput}>
                            <input
                              onChange={(e) => {
                                setActivityTitle(e.target.value);
                              }}
                              placeholder="Title here"
                            />
                          </div>
                          <div className={style.urlInput}>
                            <input
                              type="file"
                              accept="video/*"
                              onChange={handleActivityVideoFileChange}
                            />
                            <div className={style.urlButton}>
                              <button
                                onClick={() => {
                                  activityVideoUpload();
                                  setActivityPopup(false);
                                  setVideoActivity(false);
                                  setLoading(true);
                                }}
                              >
                                Save Task
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {activityUrl ? (
                      <div className={style.SummaryPopup}>
                        <div className={style.popupHead}>
                          <div className={style.HeadText}>
                            <p>Activity Url</p>
                          </div>
                          <div
                            className={style.PopupIcon}
                            onClick={() => {
                              setActivityPopup(false);
                              setActivitySelect(false);
                              setActivityUrl(false);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>

                        <div className={style.urlContent}>
                          <div className={style.TitleInput}>
                            <input
                              onChange={(e) => {
                                setActivityTitle(e.target.value);
                              }}
                              placeholder="Title here"
                            />
                          </div>
                          <div className={style.urlInput}>
                            <input
                              onChange={(e) => {
                                setActivityUploadUrl(e.target.value);
                              }}
                              placeholder="Enter url"
                            />
                            <div className={style.urlButton}>
                              <button
                                onClick={() => {
                                  activityUrlUpload("url");
                                  setActivityPopup(false);
                                  setActivityUrl(false);
                                  setUploadLoader(true);
                                }}
                                disabled={activityUploadUrl === ""}
                              >
                                Save Task
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {activityTextEdit ? (
                      <div className={style.SummaryPopup}>
                        <div className={style.popupHead}>
                          <div className={style.HeadText}>
                            <p>Edit Exercise :</p>
                          </div>
                          <div
                            className={style.PopupIcon}
                            onClick={() => {
                              setActivityPopup(false);
                              setActivitySelect(false);
                              setActivityTextEdit(false);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>

                        <div className={style.CopyContent}>
                          <div className={style.TitleInput}>
                            <input
                              value={activityTitleEdit}
                              onChange={(e) => {
                                setActivityTitleEdit(e.target.value);
                              }}
                              placeholder="Title here"
                            />
                          </div>
                          <CKEditor
                            editor={ClassicEditor}
                            data={activityeditTextData}
                            onChange={handleactivityUpdateChange}
                            onReady={(editor) => {
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  "height",
                                  "290px",
                                  editor.editing.view.document.getRoot()
                                );
                              });
                            }}
                            config={{
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                              ],
                              alignment: {
                                options: ["left", "center", "right"],
                              },
                            }}
                          />
                        </div>
                        <div className={style.SaveButton}>
                          <button
                            onClick={() => {
                              activityEdit();
                              setLoading(true);
                              setActivityPopup(false);
                              setActivityTextEdit(false);
                            }}
                          >
                            Update Exercise
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {activityUrlEdit ? (
                      <div className={style.SummaryPopup}>
                        <div className={style.popupHead}>
                          <div className={style.HeadText}>
                            <p>Activity Url</p>
                          </div>
                          <div
                            className={style.PopupIcon}
                            onClick={() => {
                              setActivityPopup(false);
                              setActivitySelect(false);
                              setActivityUrlEdit(false);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>

                        <div className={style.urlContent}>
                          <div className={style.TitleInput}>
                            <input
                              value={activityTitleEdit}
                              onChange={(e) => {
                                setActivityTitleEdit(e.target.value);
                              }}
                              placeholder="Title here"
                            />
                          </div>
                          <div className={style.urlInput}>
                            <input
                              value={activityEditUrl}
                              onChange={(e) => {
                                setActivityEditUrl(e.target.value);
                              }}
                              placeholder="Enter url"
                            />
                            <div className={style.urlButton}>
                              <button
                                onClick={() => {
                                  activityUpdateUrl();
                                  setActivityPopup(false);
                                  setActivityUrl(false);
                                  setLoading(true);
                                }}
                                disabled={activityEditUrl === ""}
                              >
                                Update Url
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className={style.Practise2}>
                <div className={style.PractiseHeadButton}>
                  {mentor === "true" ? (
                    <button
                      onClick={() => {
                        setActivityPopup(true);
                        setActivitySelect(true);
                      }}
                    >
                      Add Activity
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                <div className={style.ActivityDiv}>
                  {activityDetails && activityDetails.length > 0 ? (
                    activityDetails[0] && activityDetails[0].contents ? (
                      activityDetails[0].contents.map((activities, index) => {
                        return (
                          <>
                            <div key={index} className={style.ActivityHeading}>
                              <p>
                                {" "}
                                {index + 1}.{activities.title}
                              </p>
                              <div className={style.ActivityHeadingIcons}>
                                {mentor === "true" ? (
                                  <>
                                    {activities.type === "text" ||
                                    activities.type === "url" ? (
                                      <>
                                        {/* <span onClick={()=>{
                        comment?
                        setComment(false):setComment(true)
                        setCommentId(activities.content_id)
                      }}><FaRegComment/></span> */}
                                        <span
                                          onClick={() => {
                                            if (activities.type === "text") {
                                              setActivityeditTextData(
                                                activities.text
                                              );
                                              setActivityTextEdit(true);
                                            } else {
                                              setActivityEditUrl(
                                                activities.text
                                              );
                                              setActivityUrlEdit(true);
                                            }
                                            setActivityPopup(true);
                                            setActivityTitleEdit(
                                              activities.title
                                            );
                                            setActivityID(
                                              activityDetails[0].activity_id
                                            );
                                            setContentId(activities.content_id);
                                          }}
                                        >
                                          <FaEdit />
                                        </span>
                                        <span
                                          onClick={() => {
                                            setDeleteActivity(true);
                                            setActivityID(
                                              activityDetails[0].activity_id
                                            );
                                            setContentId(activities.content_id);
                                          }}
                                        >
                                          <RiDeleteBin5Line />
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {/* <span onClick={()=>{
                        comment?
                        setComment(false):setComment(true)
                        setCommentId(activities.content_id)
                      }}><FaRegComment/></span> */}
                                        <span
                                          onClick={() => {
                                            setDeleteActivity(true);
                                            setActivityID(
                                              activityDetails[0].activity_id
                                            );
                                            setContentId(activities.content_id);
                                          }}
                                        >
                                          <RiDeleteBin5Line />
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className={style.ActivityContent}>
                              {activities.type === "text" ? (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: activities.text,
                                  }}
                                ></p>
                              ) : activities.type === "url" ? (
                                <a
                                  href={activities.text}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {activities.text}
                                </a>
                              ) : activities.type === "video" ? (
                                <video controls width="100%" height="auto">
                                  <source
                                    src={activities.text}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : activities.type === "image" ? (
                                <div className={style.imageView}>
                                  <img src={activities.text} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              ref={commentDivRef}
                              className={style.CommentDiv}
                            >
                              <div className={style.CommentHead}>
                                {" "}
                                <p>Comments</p>
                              </div>
                              <div className={style.CommentContent}>
                                <div
                                  onClick={() => {
                                    setCommentId(activities.content_id);
                                  }}
                                  className={style.CommentEditor}
                                >
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={
                                      activityComments[activities.content_id] ||
                                      ""
                                    }
                                    onChange={(event, editor) =>
                                      commentEditorChange(
                                        event,
                                        editor,
                                        activities.content_id
                                      )
                                    }
                                    onReady={(editor) => {
                                      editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                          "height",
                                          "100px",
                                          editor.editing.view.document.getRoot()
                                        );
                                      });
                                    }}
                                    config={{
                                      toolbar: [
                                        "",
                                        "|",
                                        "bold",
                                        "italic",
                                        "link",
                                        "bulletedList",
                                        "numberedList",
                                        "blockQuote",
                                      ],
                                      placeholder: "Write a comment",
                                      alignment: {
                                        options: ["left", "center", "right"],
                                      },
                                    }}
                                  />
                                </div>
                                <div className={style.CommentButton}>
                                  <button
                                    disabled={
                                      activityComments[
                                        activities.content_id
                                      ] === ""
                                    }
                                    onClick={() => {
                                      if (commentId === activities.content_id) {
                                        commentAdd(
                                          activityDetails[0].activity_id,
                                          activities.content_id,
                                          activityComments[
                                            activities.content_id
                                          ]
                                        );
                                      } else {
                                        // setCommentText("")
                                        setActivityComments({});
                                      }
                                    }}
                                  >
                                    Post
                                  </button>
                                </div>
                                <div className={style.CommentDisplay}>
                                  {activities.comments &&
                                  activities.comments.length > 0 ? (
                                    activities.comments.map(
                                      (comment, commentIndex) => {
                                        const commentTime = new Date(
                                          comment.time
                                        );
                                        const year = commentTime.getFullYear();
                                        const month =
                                          commentTime.getMonth() + 1;
                                        const day = commentTime.getDate();
                                        const hours = commentTime.getHours();
                                        const minutes =
                                          commentTime.getMinutes();
                                        const seconds =
                                          commentTime.getSeconds();
                                        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                                        function parseAndModifyLinks(html) {
                                          const parser = new DOMParser();
                                          const doc = parser.parseFromString(
                                            html,
                                            "text/html"
                                          );

                                          const anchorTags =
                                            doc.querySelectorAll("a");
                                          anchorTags.forEach((a) => {
                                            a.setAttribute("target", "_blank");
                                          });

                                          return doc.body.innerHTML;
                                        }

                                        return (
                                          <div
                                            key={commentIndex}
                                            className={style.CommentList}
                                          >
                                            <div className={style.profileIcon}>
                                              <span>
                                                {comment.uploaded_by.name.charAt(
                                                  0
                                                )}
                                              </span>
                                            </div>
                                            <div className={style.CommentText}>
                                              <p>
                                                <strong>
                                                  {comment.uploaded_by.name}
                                                </strong>{" "}
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {formattedDateTime}
                                                </span>
                                              </p>
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: parseAndModifyLinks(
                                                    comment.content
                                                  ),
                                                }}
                                              ></p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <p>No comments yet</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div>{loading ? "" : <p>No Activities</p>}</div>
                    )
                  ) : (
                    <div>{loading ? "" : <p>No Activities</p>}</div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* {selectedTab===3 &&(
            <div className={style.labSection}>
              <div className={style.labGrid}>
                {labData&&labData.length>0?labData.map((lab,i)=>{
                  return(
                    <div className={style.labCard}>
                  <div className={style.labCardText}><p>{lab.lab_name}</p></div>
                  <div className={style.labCardLink} ><button onClick={() => window.open(lab.lab_link, '_blank')}>Get Started</button></div>
                </div>
                  )
                }):
                'No Lab'}
                
              </div>

            </div>
          )} */}

          {selectedTab === 3 && (
            <div className={style.analyticsMain}>
              <div className={style.analytics}>
                <div className={style.assessmentCount}>
                  <div className={style.assessmentIcon}>
                    <PiExam />
                  </div>
                  <div className={style.assessmentText}>
                    <p>Assessment Attended</p><span style={{color:"#3d0a24"}}>:</span>
                    <span>{analyticsData.Assesmnet_attend_count}</span>
                  </div>
                </div>
                <div className={style.ongoingTopic}>
                  <div className={style.topicLeft}>
                    <p>Ongoing Topic</p>
                  </div>
                  <span>:</span>
                  <div className={style.topicRight}>
                    <p>{analyticsData.Ongoing_Topic}</p>
                  </div>
                </div>
                <div className={style.ongoingTopic}>
                  <div className={style.assessmentIcon}>
                    <BsFillQuestionCircleFill />
                  </div>
                  <div className={style.topicText}>
                    <p>Queries Asked</p>
                    <span style={{color:"#3d0a24"}}>:</span>
                    <span>{analyticsData.query_asked_count}</span>
                  </div>
                </div>
                <div onClick={() => {
                            const source =
                              peerRole !== undefined ? "support" : "";
                            navigate("/answers", {
                              state: {
                                topicID: Topic.id,
                                peerRole: peerRole,
                                source,
                                aiId:AiId
                              },
                            });
                          }}  className={style.assessmentSummaryCount}>
                  <h4>Assessment Summary</h4>
                <div className={style.TextContainer}>
                  <div className={style.LeftText}>
                      <p>Total Mark</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>{obtainedMark||0}/{totalMark||0}</p>
                    </div>
                </div>
                <div className={style.TextContainer}>
                  <div className={style.LeftText}>
                      <p>Correct Answer</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>{correct||0}</p>
                    </div>
                </div>
                <div className={style.TextContainer}>
                  <div className={style.LeftText}>
                      <p>Wrong Answer</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>{inCorrect||0}</p>
                    </div>
                </div>
                <div className={style.TextContainer}>
                  <div className={style.LeftText}>
                      <p>Unattended</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>{unAttended||0}</p>
                    </div>
                </div>
                </div>
              </div>
              <div className={style.Graph}>
              <Bar data={data} options={options} />
              </div>
            </div>
          )}
        </Typography>
      </div>
    </div>
  );
};

export default TopicSingle;
