import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Register_img from "../../assests/Register_Work_7 1.png";
import style from "./Register.module.css";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";
import {
  Alert,
  Snackbar,
} from "@mui/material";
import { baseurl } from "../Url";
import { IoCheckmarkCircle } from "react-icons/io5";


function Register() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agreed,setAgreed]=useState(false)
  const [firstName,setFirstName]=useState("")
  const [lastName,setLastName]=useState("")
  const [userName,setUserName]=useState("")
  const [country,setCountry]=useState("")
  const [email,setEmail]=useState("")
  const [password,setPassword]=useState("")
  const [confirmPassword,setConfirmPassword]=useState("")
  const [validationErrors, setValidationErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [doConfirmPasswordsMatch, setDoConfirmPasswordsMatch] = useState(false);
  

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleCheckboxChange = (event) => {
    setAgreed(event.target.checked);
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      password: "",
    }));
    setDoPasswordsMatch(validatePassword(newPassword))
  };


  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      confirmPassword: "",
    }));
    setDoConfirmPasswordsMatch(newConfirmPassword === password);
  };

  const countries = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "India",
  ];

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = re.test(email);
    setIsEmailValid(isValid);
    return isValid;
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(password) && !/\s/.test(password);
  };
  
  const validateForm = () => {
    const errors = {};

    if (!firstName) errors.firstName = true;
    if (!lastName) errors.lastName = true;
    if (!userName) errors.userName = true;
    if (!country) errors.country = true;
    if (!email) {
      errors.email = true;
    } else if (!validateEmail(email)) {
      errors.email = true;
    }
    if (!password) {
      errors.password = true;
    } else if (!validatePassword(password)) {
      errors.password = true;
    }
    if (!confirmPassword) {
      errors.confirmPassword = true;
    } else if (password !== confirmPassword) {
      errors.confirmPassword = true;
    }
    if (!agreed) errors.agreed = true;

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      userSignUp()
    }
  };

  async function userSignUp() {
    const body = {
      FirstName: firstName,
      LastName: lastName,
      Username: userName,
      Password: password,
      Email: email,
      country: country,
    };
   
    await fetch(`${baseurl}/account/signup`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") { 
          navigate("/email/verification",{
            state:{
              Email:email
            }
          })
         
        } else if (data.status === "error" || data.status === "failed") {
          
        }
      });
  }


  return (
    <div className={style.Container}>
      <div className={style.RegisterBody}>
        <div className={style.registerBodyLeft}>
          <div className={style.formBox}>
            <div className={style.welcomeTxt}>
              <p className={style.welcomeHead}>
                {" "}
                Welcome to <span>English Wagon</span>{" "}
              </p>
              <p className={style.welcomeSubHead}>
                Start your journey to English mastery!
              </p>
            </div>
  
            <div className={style.formContainer}>
              <div className={style.formFeilds}>
                <div className={style.formGroup}>
                  <div  className={`${style.names} ${validationErrors.firstName ? style.error : ''}`}>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      required
                      value={firstName}
                      onChange={(e)=>{
                        setFirstName(e.target.value)
                        setValidationErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
                      }}
                    />
                  </div>
                  <div className={`${style.names} ${validationErrors.lastName ? style.error : ''}`}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      required
                      value={lastName}
                      onChange={(e)=>{
                        setLastName(e.target.value)
                        setValidationErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                      }}
                    />
                  </div>
                </div>
  
                <div className={style.formGroup}>
                  <div className={`${style.Username} ${validationErrors.userName ? style.error : ''}`} >
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      required
                      value={userName}
                      onChange={(e)=>{
                        setUserName(e.target.value)
                        setValidationErrors((prevErrors) => ({ ...prevErrors, userName: '' }));
                      }}
                    />
                  </div>
                  <div className={`${style.selectContainer} ${validationErrors.country ? style.error : ''}`} >
                    <select 
                    name="Country"  
                    required
                    value={country}
                      onChange={(e)=>{
                        setCountry(e.target.value)
                        setValidationErrors((prevErrors) => ({ ...prevErrors, country: '' }));
                      }}
                    >
                      <option disabled value="">Country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
  
                <div className={style.formGroup}>
                  <div className={`${style.emailBox} ${validationErrors.email ? style.error : ''}`} >
                    
                    <input
                      type="text"
                      placeholder="E-mail"
                      name="email"
                      required
                      value={email}
                      onChange={(e)=>{
                        setEmail(e.target.value)
                        setValidationErrors((prevErrors) => ({ ...prevErrors, email: '' }));
                      }}
                         />
                         {isEmailValid && (
                      <span className={style.checkMark}>
                        <IoCheckmarkCircle />
                      </span>
                    )}
                  </div>
                  
                </div>
  
                <div className={style.formGroup}>
                  <div className={`${style.passwordForm} ${validationErrors.password ? style.error : ''}`} >
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="psw"
                      required
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {/* <span onClick={togglePasswordVisibility} className={style.eyeIco}>
                      {showPassword ? <RxEyeOpen /> : <RxEyeClosed />}
                    </span> */}
                    {doPasswordsMatch && (
                      <span className={style.checkMark}>
                        <IoCheckmarkCircle />
                      </span>
                    )}
                  </div>
                  <div className={`${style.passwordForm} ${validationErrors.confirmPassword ? style.error : ''}`} >
                    <input
                      style={{width:"95%"}}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      name="psw"
                      required
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    {doConfirmPasswordsMatch && (
                      <span className={style.checkMark}>
                        <IoCheckmarkCircle />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {validationErrors.password &&password.length>0 ? (
                  <div className={style.passwordCondition}>
                    <div className={style.specialCharacter}>*</div>
                    <div className={style.passwordConditionText}>
                      <p>
                        At least 8 characters, 1 uppercase letter,{" "}
                        <span>1 number</span> and{" "}
                        <span>1 special character.</span>
                      </p>
                    </div>
                  </div>
                ) : null}
  
                <div className={style.declare}>
                <label className={style.customCheckbox}>
                  <input
                    type="checkbox"
                    name="remember"
                    checked={agreed}
                    onChange={handleCheckboxChange}
                  />
                  <span className={style.checkmark}></span>
                  &nbsp;
                  <p>
                    I am aged 18 or over and agree to the{" "}
                    <span>Terms and conditions</span> and{" "}
                    <span>Privacy policy</span>{" "}
                  </p>
                </label>
              </div>
  
              <button  style={{backgroundColor:!agreed?"#898890":"",opacity:!agreed?"0.75":""}} disabled={!agreed} onClick={(e) =>handleSubmit(e)}>Sign Up</button>
  
              <div className={style.signUp}>
                <p>Already have an account?</p>
                <p>
                  <span onClick={()=>{
                    navigate("/login")
                  }}>Login</span>
                </p>
              </div>
            </div>
          </div>
        </div>

       <div className={style.registerBodyRight}>
          <div className={style.groupImg}>
            <img src={Register_img} alt="English Wagon register" />
          </div>
       </div>
      </div>
    </div>
  );
}

export default Register;
