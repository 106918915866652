import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,

    REGISTER_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,


    COUNTRY_SUCCESS,
    COUNTRY_FAIL,
    COUNTRY_REQUEST,

    PROFILE_FAIL,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
  } from "../constants/user";

  export const userReducer=(state={users:[]},action)=>{
    switch (action.type) {
        case LOGIN_REQUEST: 
         case  REGISTER_REQUEST:
            return{
                loading:true,
                authenticated: false,
            }
            case LOGIN_SUCCESS:
                case REGISTER_SUCCESS:
                return{
                    loading:false,
                    users:action.payload,
                    authenticated: true,
                }
                case LOGIN_FAIL:
                    case REGISTER_FAIL:
            return{
                loading:false,
               error:action.payload,
               authenticated: false,
            }
    
        default:
            return{
                ...state,
            }
    }
}
export const countryReducer=(state={users:[]},action)=>{
    switch (action.type) {
        case COUNTRY_REQUEST: 
        
            return{
                loading:true,
               
            }
                case COUNTRY_SUCCESS:
                return{
                    loading:false,
                    country:action.payload,
                    
                }
                case LOGIN_FAIL:
                    case COUNTRY_FAIL:
            return{
                loading:false,
               error:action.payload,
              
            }
    
        default:
            return{
                ...state,
            }
    }
}

export const profileReducer = (state = { profile: [] }, action) => {
    switch (action.type) {
        case PROFILE_REQUEST:
            return {
                loading: true,
            };

        case PROFILE_SUCCESS:
            return {
                loading: false,
                profile: action.payload.data[0],
            };

        case PROFILE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};