import {createStore,combineReducers,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import { userReducer,countryReducer,profileReducer} from './reducers/user'

const reducers=combineReducers({
    userReducer:userReducer,
    countryReducer:countryReducer,
    profileData: profileReducer,
})
 const middleware=[thunk]
 const InitialState={

 }

const store = createStore(reducers,InitialState, composeWithDevTools(applyMiddleware(...middleware)))


export default store;