import React, { useState, useEffect } from "react";
import style from "./Sidebar.module.css";
import DashboardIcon from "../../assests/Dashboard.png";
import LearningIcon from "../../assests/Learning.png";
import PerformanceIcon from "../../assests/Performance.png";
import ProfileIcon from "../../assests/User.png";
import BillingIcon from "../../assests/Billing.png";
import SettingsIcon from "../../assests/Settings.png";
import { useNavigate, useLocation } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";

const Sidebar = ({ finished, setFinishPopup, timeRunning, attended }) => {
  const menu = [
    {
      id: 1,
      name: "Homepage",
      router: "/",
      icon: DashboardIcon,
      specialPaths: ["/daily/drill", "/weekly/workout"],
    },
    // {
    //     id:2,
    //     name:"Learnings",
    //     router:"/learnings",
    //     icon:LearningIcon
    // },
    {
      id: 7,
      name: "Learnings",
      router: "/new/learnings",
      icon: LearningIcon,
      specialPaths: ["/new/learnings/detail", "/assessments"],
    },
    {
      id: 3,
      name: "Performance",
      router: "/performance",
      icon: PerformanceIcon,
    },
    {
      id: 4,
      name: "My Profile",
      router: "/profile",
      icon: ProfileIcon,
    },
    {
      id: 5,
      name: "Subscription",
      router: "/subscribe/details",
      icon: BillingIcon,
    },
    {
      id: 6,
      name: "Settings",
      router: "",
      icon: SettingsIcon,
    },
  ];

  const UserID = localStorage.getItem("USERID");

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = useState({});
  const [logout, setLogout] = useState(false);

  const handleClick = (item) => {
    if (
      location.pathname === "/daily/drill" &&
      !finished &&
      timeRunning &&
      attended
    ) {
      setFinishPopup(true);
    } else if (
      location.pathname === "/weekly/workout" &&
      !finished &&
      timeRunning &&
      attended
    ) {
      setFinishPopup(true);
    } else {
      setSelectedMenu(item);
      navigate(item.router);
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("access-token");
    localStorage.removeItem("ClubID");
    localStorage.removeItem("ORGID");
    localStorage.removeItem("USERID");
    localStorage.removeItem("mentor");
    localStorage.removeItem("learnertoken");
    localStorage.removeItem("learneruserid");
    localStorage.removeItem("username");
    localStorage.removeItem("newLearningId");
    localStorage.removeItem("MainLevelId");
    localStorage.removeItem("SubLevelId");
    localStorage.removeItem("clickedItem");
    localStorage.removeItem("clickeditemDes");
    localStorage.removeItem("functionCalled");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("countrydetails");
    localStorage.removeItem("timezone");
    localStorage.removeItem("currency");
    localStorage.removeItem("adminmapped");
    localStorage.removeItem("dailySnoozeTriggered");
    localStorage.removeItem("weeklySnoozeTriggered");
    localStorage.removeItem("hasShownDailyDrill");
    googleLogout();
    navigate("/login");
    window.location.reload();
  };

  useEffect(() => {
    const currentMenuItem = menu.find(
      (item) =>
        item.router === location.pathname ||
        (item.specialPaths && item.specialPaths.includes(location.pathname))
    );
    if (currentMenuItem) {
      setSelectedMenu(currentMenuItem);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (UserID === null || UserID === undefined) {
      navigate("/login");
    }
  }, []);

  return (
    <div className={style.sidebar}>
      <div className={style.MenuContainer}>
        {menu.map((item) => {
          return (
            <div
              onClick={() => {
                handleClick(item);
              }}
              key={item.id}
              className={
                selectedMenu.id === item.id
                  ? style.selectedMenu
                  : style.menuItem
              }
            >
              <div className={style.menuItemImage}>
                <img src={item.icon} alt={item.name} />
              </div>
              <div className={style.menuItemText}>
                <p>{item.name}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.bottomContent}>
        <div className={style.separateLine}></div>
        <div className={style.bottomText}>
          <p className={style.supportText}>Support</p>
        </div>
        <div className={style.bottomText}>
          <p
            className={style.logoutText}
            onClick={() => {
              if (
                location.pathname === "/daily/drill" &&
                !finished &&
                timeRunning
              ) {
                setFinishPopup(true);
              } else if (
                location.pathname === "/weekly/workout" &&
                !finished &&
                timeRunning
              ) {
                setFinishPopup(true);
              } else {
                setLogout(true);
              }
            }}
          >
            Log out
          </p>
        </div>
      </div>
      {logout ? (
        <>
          <div className={style.popupOverlay}></div>
          <div className={style.Popup}>
            <div className={style.popupText}>
              <p>Are you sure you want to log out?</p>
            </div>
            <div className={style.PopupButton}>
              <button
                className={style.noButton}
                onClick={() => {
                  setLogout(false);
                }}
              >
                NO
              </button>
              <button
                className={style.yesButton}
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout(e);
                }}
              >
                YES
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sidebar;
