import React, { useState, useEffect } from "react";
import style from "./Cookie.module.css";
import cookieImage from "../../assests/bitte_cokkie.png";
import Switch from "react-switch";
import DisabledCookieImage from '../../assests/disabledCookie.png' 

const Cookie = () => {
  const [privacyPopUp, setPrivacyPopup] = useState(false);

  const [checked, setChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookie-consent', 'rejected');
    setIsVisible(false);
  };

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (consent) {
      setIsVisible(false);
    }
  }, []);

  return (
    isVisible && (
    <div className={style.container}>
      <div className={style.banner} style={{backgroundColor:privacyPopUp?"#898890":""}}>
        <div className={style.bannerLeft}>
          <div className={style.cookieImageOne}>
            <img src={privacyPopUp?DisabledCookieImage:cookieImage} alt="cookie" />
          </div>
          <div className={style.cookieImageTwo}>
            <img src={privacyPopUp?DisabledCookieImage:cookieImage} alt="cookie" />
          </div>
          <div className={style.cookieImageThree}>
            <img src={privacyPopUp?DisabledCookieImage:cookieImage} alt="cookie" />
          </div>
        </div>
        <div className={style.bannerRight}>
          <div className={style.cookieText}>
            <p>
              By clicking “Accept all”, you agree to the storing of cookies on
              your device to enhance site navigation , analyze site usage, and
              assist in our marketing efforts. <span>Privacy policy</span>
            </p>
          </div>
          <div className={style.cookieBottomContent}>
            <div className={style.cookieBottomLeftContent}>
              <p
                onClick={() => {
                  setPrivacyPopup(true);
                }}
              >
                Manage cookie settings
              </p>
            </div>
            <div className={`${style.cookieBottomRightContent} ${privacyPopUp ? style.disabledButton : ""}`}>
              <button onClick={handleAccept}>Accept all</button>
              <button onClick={handleReject}>Reject all</button>
            </div>
          </div>
        </div>
      </div>
      {privacyPopUp ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.privacyPopUp}>
            <div className={style.privacyPopUpHeder}>
              <p>Privacy Preference Center</p>
            </div>
            <div className={style.privacyPopUpBody}>
              <div className={style.privacyPopUpBodyText}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <span>More information</span>
              </div>
              <div className={style.privacyPopUpBodyButton}>
                <button onClick={handleAccept}>Accept all</button>
              </div>
              <div className={style.privacyPopUpBodyTable}>
                <div className={style.privacyTableLeft}>
                  <div className={style.privacyTableRow}>
                    <div className={style.privacyTableRowLeft}>
                      Functional cookies
                    </div>
                    <div className={style.privacyTableRowRight}>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        offColor="#888"
                        onColor="#595BDB"
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </div>
                  </div>
                  <div className={style.privacyTableRow}>
                    <div className={style.privacyTableRowLeft}>
                      <p>Targeting cookies</p>
                    </div>
                    <div className={style.privacyTableRowRight}>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        offColor="#888"
                        onColor="#595BDB"
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </div>
                  </div>
                  <div className={style.privacyTableRow}>
                    <div className={style.privacyTableRowLeft}>
                      <p>Performance cookies</p>
                    </div>
                    <div className={style.privacyTableRowRight}>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        offColor="#888"
                        onColor="#595BDB"
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </div>
                  </div>
                  <div className={style.privacyTableRow}>
                    <div className={style.privacyTableRowLeft}>
                      <p>Strictly necessary cookies</p>
                    </div>
                    <div className={style.privacyTableRowRight}>
                      <p>Always active</p>
                    </div>
                  </div>
                </div>
                <div className={style.privacyTableRight}>
                  <button
                    onClick={() => {
                      setPrivacyPopup(false);
                    }}
                  >
                    Reject all
                  </button>
                  <button>Confirm my choice</button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  ));
};

export default Cookie;
